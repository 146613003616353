import { memo } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { ROUTERS } from "Utils/router";

const Footer = () => {
    return (
    <footer className="footer">
        <div className="container">
            <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="footer__about">
                    <h1 className="footer__about__logo">SmashElite</h1>
                    <ul>
                        <li>Địa chỉ: 213 Chu Văn An</li>
                        <li>Phone: 0977-232-232</li>
                        <li>Email: SmashElite@gmail.com</li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="footer__widget">
                    <h6>SERVICES & OTHER</h6>
                    <ul>
                        <li>
                            Term & Conditions
                        </li>
                        <li>
                            Refund and Returns Policy
                        </li>
                        <li>
                            Delivery Policy
                        </li>
                        <li>
                            Cancelation Policy
                        </li>
                    </ul>
                    <ul>
                        <li>
                            <Link to={ROUTERS.USER.HOME}>Home</Link>
                        </li>
                        <li>
                        <Link to={ROUTERS.USER.PRODUCTS}>Shop</Link>
                        </li>
                        <li>
                            <Link to={ROUTERS.USER.ABOUT}>About</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
                <div className="footer__widget">
                    <h6>FOLLOW</h6>
                    <form action="#">
                        <div className="footer__widget__social">
                            <div>
                                <Link to="https://www.facebook.com/profile.php?id=61565988783148">
                                    <BsFacebook />
                                </Link>
                            </div>
                            <div>
                                <Link to="https://www.instagram.com/sme_badminton/">
                                    <BsInstagram />
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            </div>
        </div>
    </footer>
    );
};

export default memo(Footer);