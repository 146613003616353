import { memo, useState, useEffect } from "react";
import "./style.scss";
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";

const images = [
  {
    url: "https://down-vn.img.susercontent.com/file/vn-11134207-7r98o-lun6cr9b3pd1d9_tn",
    title: '"Customized Racket Grip"',
    description:
      "Khám phá cách tùy chỉnh độ bám cho cây vợt cầu lông của bạn, mang lại trải nghiệm chơi hoàn hảo và phong cách riêng.",
  },
  {
    url: "https://down-vn.img.susercontent.com/file/sg-11134201-7rdxd-lydndx0udsxj0c_tn.webp",
    title: '"Personalized Badminton Bag"',
    description:
      "Thiết kế túi cầu lông riêng của bạn với các màu sắc và họa tiết yêu thích, giúp bạn nổi bật trên sân đấu.",
  },
  {
    url: "https://imgcdn.thitruongsi.com/tts/rs:fill:320:320:1:1/g:sm/plain/file://product/2024/05/25/0b492d40-1a47-11ef-bb33-0ff2f9aae4bf.jpg",
    title: '"Custom Shuttlecock"',
    description:
      "Tạo ra những quả cầu cầu lông độc đáo với thiết kế và màu sắc riêng, nâng cao sự vui vẻ trong mỗi trận đấu.",
  },
  {
    url: "https://kbsportshub.ie/cdn/shop/files/Screenshot-20240205_202409_Google.jpg?v=1707165183&width=1039",
    title: '"Unique Racket Design"',
    description:
      "Biến cây vợt cầu lông của bạn thành một tác phẩm nghệ thuật với các mẫu và màu sắc được tùy chỉnh riêng.",
  },
  {
    url: "https://www.badmintonmag.com/wp-content/uploads/2019/02/Badminton-rackets-of-the-Pros.webp",
    title: '"Custom Badminton Accessories"',
    description:
      "Đem đến cho bộ phụ kiện cầu lông của bạn sự mới mẻ với các tùy chọn cá nhân hóa không giới hạn.",
  },
  {
    url: "https://shoppingkingdom.lk/wp-content/uploads/2020/04/5a75067368778d67d8449f5d79fbb693.jpg",
    title: '"Tailored Badminton Outfit"',
    description:
      "Thiết kế trang phục cầu lông phù hợp với phong cách của bạn, giúp bạn tự tin tỏa sáng trên sân.",
  },
];

const Slider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  useEffect(() => {
    const timer = setInterval(handleNext, 3000); // Change slide every 3 seconds
    return () => clearInterval(timer);
  }, []);

  return (
    <main>
      <ul className="slider">
        {images.map((image, index) => (
          <SliderItem
            image={image}
            key={image.url}
            index={index}
            currentIndex={currentIndex}
          />
        ))}
      </ul>
      <nav className="nav">
        <button className="btn prev" onClick={handlePrev} aria-label="Previous Slide">
          <GrFormPreviousLink />
        </button>
        <button className="btn next" onClick={handleNext} aria-label="Next Slide">
          <GrFormNextLink />
        </button>
      </nav>
    </main>
  );
};

const SliderItem = ({ image, index, currentIndex }) => {
  const isActive = index === currentIndex;
  return (
    <li
      className="item"
      style={{
        backgroundImage: `url(${image.url})`,
        opacity: isActive ? 1 : 0,
        visibility: isActive ? "visible" : "hidden",
        zIndex: isActive ? 1 : 0,
        transition: "opacity 0.75s ease-in-out, visibility 0.75s ease-in-out",
      }}
    >
      <div
        className="content"
        style={{
          opacity: isActive ? 1 : 0,
          transition: "opacity 0.75s ease-in-out",
        }}
      >
        <h2 className="title" aria-live="polite">
          {image.title}
        </h2>
        <p className="description" aria-live="polite">
          {image.description}
        </p>
      </div>
    </li>
  );
};

export default memo(Slider);
