import { memo, useState } from "react";
import "./style.scss";
import { Sidebar } from "Components";
import { IoCreateOutline } from "react-icons/io5";

const CreateOrderPage = () => {
    const [category, setCategory] = useState({
        customerId: '',
        orderId: '',
        status: '',
        total: '',
        createDatetime: '',
        orderDatetime: '',
        updateDatetime: '',
        customerAddress: '',
        customerName: '',
        customerPhone: '',
        orderStatus: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCategory(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted category data:", category);
    };

    return (
        <div className="main d-flex">
            {/* Sidebar Section */}
            <div className="sidebarWrapper">
                <Sidebar />
            </div>
            {/* Content Section */}
            <div className="content">
                <div className="card shadow border-0 p-3 mt-4">
                    <form onSubmit={handleSubmit} className="mb-4">
                        <div className="form-group">
                            <label htmlFor="customerId">Customer ID</label>
                            <input
                                type="text"
                                id="customerId"
                                name="customerId"
                                className="form-control"
                                value={category.customerId}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="orderId">Order ID</label>
                            <input
                                type="text"
                                id="orderId"
                                name="orderId"
                                className="form-control"
                                value={category.orderId}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <input
                                type="text"
                                id="status"
                                name="status"
                                className="form-control"
                                value={category.status}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="total">Total</label>
                            <input
                                type="text"
                                id="total"
                                name="total"
                                className="form-control"
                                value={category.total}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="createDatetime">Create Datetime</label>
                            <input
                                type="datetime-local"
                                id="createDatetime"
                                name="createDatetime"
                                className="form-control"
                                value={category.createDatetime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="orderDatetime">Order Datetime</label>
                            <input
                                type="datetime-local"
                                id="orderDatetime"
                                name="orderDatetime"
                                className="form-control"
                                value={category.orderDatetime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="updateDatetime">Update Datetime</label>
                            <input
                                type="datetime-local"
                                id="updateDatetime"
                                name="updateDatetime"
                                className="form-control"
                                value={category.updateDatetime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="customerAddress">Customer Address</label>
                            <input
                                type="text"
                                id="customerAddress"
                                name="customerAddress"
                                className="form-control"
                                value={category.customerAddress}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="customerName">Customer Name</label>
                            <input
                                type="text"
                                id="customerName"
                                name="customerName"
                                className="form-control"
                                value={category.customerName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="customerPhone">Customer Phone</label>
                            <input
                                type="text"
                                id="customerPhone"
                                name="customerPhone"
                                className="form-control"
                                value={category.customerPhone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="orderStatus">Order Status</label>
                            <input
                                type="text"
                                id="orderStatus"
                                name="orderStatus"
                                className="form-control"
                                value={category.orderStatus}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            <IoCreateOutline /> Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default memo(CreateOrderPage);
