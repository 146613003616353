import { memo, useState } from "react";
import "./style.scss";
import { Sidebar } from "Components";
import { IoCreateOutline } from "react-icons/io5";

const CreateProductPage = () => {
    const [product, setProduct] = useState({
        brandId: '',
        categoryId: '',
        price: '',
        productId: '',
        status: '',
        stock: '',
        createDatetime: '',
        updateDatetime: '',
        code: '',
        description: '',
        productName: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted product data:", product);
    };

    return (
        <div className="main d-flex">
            {/* Sidebar Section */}
            <div className="sidebarWrapper">
                <Sidebar />
            </div>
            {/* Content Section */}
            <div className="content">
                <div className="card shadow border-0 p-3 mt-4">
                    <form onSubmit={handleSubmit} className="mb-4">
                        <div className="form-group">
                            <label htmlFor="brandId">Brand ID</label>
                            <input
                                type="text"
                                id="brandId"
                                name="brandId"
                                className="form-control"
                                value={product.brandId}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="categoryId">Category ID</label>
                            <input
                                type="text"
                                id="categoryId"
                                name="categoryId"
                                className="form-control"
                                value={product.categoryId}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="price">Price</label>
                            <input
                                type="number"
                                id="price"
                                name="price"
                                className="form-control"
                                value={product.price}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="productId">Product ID</label>
                            <input
                                type="text"
                                id="productId"
                                name="productId"
                                className="form-control"
                                value={product.productId}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <input
                                type="text"
                                id="status"
                                name="status"
                                className="form-control"
                                value={product.status}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="stock">Stock</label>
                            <input
                                type="number"
                                id="stock"
                                name="stock"
                                className="form-control"
                                value={product.stock}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="createDatetime">Create Datetime</label>
                            <input
                                type="datetime-local"
                                id="createDatetime"
                                name="createDatetime"
                                className="form-control"
                                value={product.createDatetime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="updateDatetime">Update Datetime</label>
                            <input
                                type="datetime-local"
                                id="updateDatetime"
                                name="updateDatetime"
                                className="form-control"
                                value={product.updateDatetime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="code">Code</label>
                            <input
                                type="text"
                                id="code"
                                name="code"
                                className="form-control"
                                value={product.code}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="description">Description</label>
                            <input
                                type="text"
                                id="description"
                                name="description"
                                className="form-control"
                                value={product.description}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="productName">Product Name</label>
                            <input
                                type="text"
                                id="productName"
                                name="productName"
                                className="form-control"
                                value={product.productName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            <IoCreateOutline /> Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default memo(CreateProductPage);
