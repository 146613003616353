import { memo, useState } from "react";
import "./style.scss";
import { Sidebar } from "Components";
import { IoCreateOutline } from "react-icons/io5";

const CreateUserPage = () => {
    const [user, setUser] = useState({
        status: '',
        user: '',
        userId: '',
        createDatetime: '',
        updateDatetime: '',
        accountName: '',
        email: '',
        phone: '',
        password: '',
        role: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Submitted user data:", user);
    };

    return (
        <div className="main d-flex">
            {/* Sidebar Section */}
            <div className="sidebarWrapper">
                <Sidebar />
            </div>
            {/* Content Section */}
            <div className="content">
                <div className="card shadow border-0 p-3 mt-4">
                    <form onSubmit={handleSubmit} className="mb-4">
                        <div className="form-group">
                            <label htmlFor="status">Status</label>
                            <input
                                type="text"
                                id="status"
                                name="status"
                                className="form-control"
                                value={user.status}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="user">User</label>
                            <input
                                type="text"
                                id="user"
                                name="user"
                                className="form-control"
                                value={user.user}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="userId">User ID</label>
                            <input
                                type="text"
                                id="userId"
                                name="userId"
                                className="form-control"
                                value={user.userId}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="createDatetime">Create Datetime</label>
                            <input
                                type="datetime-local"
                                id="createDatetime"
                                name="createDatetime"
                                className="form-control"
                                value={user.createDatetime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="updateDatetime">Update Datetime</label>
                            <input
                                type="datetime-local"
                                id="updateDatetime"
                                name="updateDatetime"
                                className="form-control"
                                value={user.updateDatetime}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="accountName">Account Name</label>
                            <input
                                type="text"
                                id="accountName"
                                name="accountName"
                                className="form-control"
                                value={user.accountName}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                value={user.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                className="form-control"
                                value={user.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="password">Password</label>
                            <input
                                type="password"
                                id="password"
                                name="password"
                                className="form-control"
                                value={user.password}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="role">Role</label>
                            <input
                                type="text"
                                id="role"
                                name="role"
                                className="form-control"
                                value={user.role}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <button type="submit" className="btn btn-primary">
                            <IoCreateOutline /> Create
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default memo(CreateUserPage);
