import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "Components";
import instance from "Utils/axiosCustom"; // Tùy chỉnh axios đã cấu hình
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import "./style.scss"; // Style cho trang

const ColorPage = () => {
  // Các state lưu trữ dữ liệu và trạng thái trang
  const [searchColorValue, setSearchColorValue] = useState("");
  const [searchColorCode, setSearchColorCode] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [colors, setColors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Fetch colors từ API khi trang được mount hoặc khi có thay đổi search/filter
  useEffect(() => {
    const fetchColors = async () => {
      setLoading(true); // Bắt đầu hiển thị trạng thái loading
      try {
        const response = await instance.get("colors", {
          params: {
            colorValue: searchColorValue || undefined,
            colorCode: searchColorCode || undefined,
            status: searchStatus !== "" ? searchStatus === "true" : undefined,
            page: currentPage - 1, // API bắt đầu page từ 0
            size: 10,
          },
        });

        if (response.data && response.data.content) {
          setColors(response.data.content); // Lưu dữ liệu colors
          setTotalPages(response.data.totalPages); // Tổng số trang
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching colors");
      } finally {
        setLoading(false); // Kết thúc trạng thái loading
      }
    };

    fetchColors();
  }, [currentPage, searchColorValue, searchColorCode, searchStatus]);

  // Search Handler
  const handleSearch = () => {
    setCurrentPage(1); // Reset trang về 1 khi có thay đổi tìm kiếm
  };

  // Pagination Handlers
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage); // Thay đổi trang hiện tại
    }
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Colors</h3>
          {/* Tạo Color Button */}
          <Link to="/admin/colors/create">
            <button className="btn btn-success">Create Color</button>
          </Link>

          {/* Input tìm kiếm */}
          <div className="mb-3 mt-4 d-flex">
            {/* Tìm kiếm theo colorValue */}
            <input
              type="text"
              className="form-control-1"
              placeholder="Search by color value..."
              value={searchColorValue}
              onChange={(e) => setSearchColorValue(e.target.value)}
              aria-label="Search by color value"
            />

            {/* Tìm kiếm theo colorCode */}
            <input
              type="text"
              className="form-control-1 ms-3"
              placeholder="Search by color code..."
              value={searchColorCode}
              onChange={(e) => setSearchColorCode(e.target.value)}
              aria-label="Search by color code"
            />

            {/* Tìm kiếm theo status */}
            <select
              value={searchStatus}
              onChange={(e) => setSearchStatus(e.target.value)}
              className="form-control-1 ms-3"
              aria-label="Search by status"
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>

            <button
              className="btn btn-primary button-search ms-3"
              onClick={handleSearch}
              aria-label="Search"
            >
              Search
            </button>
          </div>

          {/* Hiển thị trạng thái loading */}
          {loading && <div className="alert alert-info">Loading colors...</div>}

          {/* Hiển thị lỗi nếu có */}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Bảng hiển thị danh sách color */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">ColorID</th>
                <th scope="col">Color Value</th>
                <th scope="col">Color Code</th>
                <th scope="col">Status</th>
                <th scope="col">Create datetime</th>
                <th scope="col">Update datetime</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {colors.length > 0 ? (
                colors.map((color) => (
                  <tr key={color.colorId}>
                    <td>{color.colorId}</td>
                    <td>{color.colorValue}</td>
                    <td>
                      <div
                        style={{
                          backgroundColor: color.colorCode,
                          width: "50px",
                          height: "20px",
                        }}
                      />
                    </td>
                    <td>{color.status ? "Active" : "Inactive"}</td>
                    <td>{new Date(color.createdAt).toLocaleString()}</td>
                    <td>{new Date(color.updatedAt).toLocaleString()}</td>
                    <td>
                      <Link to={`/admin/colors/edit/${color.colorId}`}>
                        <button
                          className="btn btn-success"
                          aria-label="Edit color"
                        >
                          <IoCreateOutline />
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        aria-label="Delete color"
                        // Xử lý sự kiện delete sau
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No colors found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Phân trang */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-secondary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ColorPage);
