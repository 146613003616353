import React from 'react';
import { Outlet } from 'react-router-dom'; // Import Outlet to render child routes
import Header from '../header';
import Footer from '../footer';

const MasterLayout = () => {
    return (
        <div className="master-layout">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
};

export default MasterLayout;
