import React from 'react';
import { Outlet } from 'react-router-dom';
import Headeradmin from '../headeradmin';

const AdminLayout = () => {
    return (
        <div className="admin-layout">
            <Headeradmin />
            <Outlet />
        </div>
    );
};

export default AdminLayout;
