import React, { memo, useState, useEffect } from "react";
import { Sidebar } from "Components";
import instance from "Utils/axiosCustom";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";

// Thiết lập Modal
Modal.setAppElement("#root");

const OrderPage = () => {
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Modal state
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDetailsModalOpen, setDetailsModalOpen] = useState(false);

  // Filter state
  const [filter, setFilter] = useState({
    minPrice: "",
    maxPrice: "",
    customerPhone: "",
    customerAddress: "",
    customerName: "",
    orderStatus: "",
    startDate: "",
    orderCode: "",
  });

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const response = await instance.get("order", {
          params: {
            page: currentPage - 1,
            size: 10,
            ...filter,
          },
        });
        if (response.data && response.data.content) {
          setOrders(response.data.content);
          setTotalPages(response.data.totalPages);
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching orders");
      } finally {
        setLoading(false);
      }
    };
    fetchOrders();
  }, [currentPage, filter]);

  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleViewDetails = (orderId) => {
    const order = orders.find((order) => order.orderId === orderId);
    setSelectedOrder(order);
    setDetailsModalOpen(true);
  };

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.value,
    });
  };

  const applyFilter = () => {
    setCurrentPage(1);
  };

  // Function to handle status change
  const handleStatusChange = async (orderId, newStatus) => {
    try {
      const response = await instance.put(`carts/${orderId}/status?status=${newStatus}`);
      toast.success("Order status updated successfully");
      // Update the local order status after successful API response
      setOrders((prevOrders) =>
        prevOrders.map((order) =>
          order.orderId === orderId ? { ...order, orderStatus: newStatus } : order
        )
      );
    } catch (error) {
      toast.error("Failed to update order status");
    }
  };
  const getAvailableStatuses = (currentStatus) => {
    const statusOrder = ["PENDING",  "SHIPPED", "DELIVERED", "CANCELLED"];
    const currentIndex = statusOrder.indexOf(currentStatus);
    // Return only the statuses that are equal to or come after the current status
    return statusOrder.slice(currentIndex);
  };
  // Modal custom style
  const modalStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      transform: "translate(-50%, -50%)",
      width: "80%", 
      maxWidth: "800px",
      padding: "20px",
    },
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Orders</h3>

          {/* Filter Section */}
          <div className="filter-section mb-3">
            <div className="d-flex justify-content-between">
              <div className="filter-group">
                <input
                  type="text"
                  name="customerName"
                  className="form-control"
                  placeholder="Customer Name"
                  value={filter.customerName}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="filter-group">
                <input
                  type="text"
                  name="customerPhone"
                  className="form-control"
                  placeholder="Customer Phone"
                  value={filter.customerPhone}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="filter-group">
                <input
                  type="text"
                  name="customerAddress"
                  className="form-control"
                  placeholder="Customer Address"
                  value={filter.customerAddress}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="filter-group">
                <input
                  type="number"
                  name="minPrice"
                  className="form-control"
                  placeholder="Min Price"
                  value={filter.minPrice}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="filter-group">
                <input
                  type="number"
                  name="maxPrice"
                  className="form-control"
                  placeholder="Max Price"
                  value={filter.maxPrice}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="filter-group">
                <input
                  type="date"
                  name="startDate"
                  className="form-control"
                  placeholder="Start Date"
                  value={filter.startDate}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="filter-group">
                <input
                  type="text"
                  name="orderCode"
                  className="form-control"
                  placeholder="Order Code"
                  value={filter.orderCode}
                  onChange={handleFilterChange}
                />
              </div>
              <div className="filter-group">
                <select
                  name="orderStatus"
                  className="form-control"
                  value={filter.orderStatus}
                  onChange={handleFilterChange}
                >
                  <option value="">Order Status</option>
                  <option value="PENDING">PENDING</option>
                  <option value="PROCESSING">PROCESSING</option>
                  <option value="COMPLETED">COMPLETED</option>
                  <option value="CANCELLED">CANCELLED</option>
                </select>
              </div>
              <button className="btn btn-primary" onClick={applyFilter}>
                Apply Filter
              </button>
            </div>
          </div>

          {/* Loading and error messages */}
          {loading && <div className="alert alert-info">Loading orders...</div>}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Order Table */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Order ID</th>
                <th scope="col">Order Code</th>
                <th scope="col">Total</th>
                <th scope="col">Customer Name</th>
                <th scope="col">Customer Phone</th>
                <th scope="col">Order Status</th>
                <th scope="col">Order Date</th>
                <th>View Details</th>
              </tr>
            </thead>
            <tbody>
              {orders.length > 0 ? (
                orders.map((order) => (
                  <tr key={order.orderId}>
                    <td>{order.orderId}</td>
                    <td>{order.orderCode}</td>
                    <td>${order.total.toFixed(2)}</td>
                    <td>{order.customerName}</td>
                    <td>{order.customerPhone}</td>
                    <td>
                      {/* Dropdown to select order status */}
                     <select
                        value={order.orderStatus}
                        onChange={(e) => handleStatusChange(order.orderId, e.target.value)}
                        className="form-control"
                      >
                        {getAvailableStatuses(order.orderStatus).map((status) => (
                          <option key={status} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>{new Date(order.orderDate).toLocaleString()}</td>
                    <td>
                      <button
                        className="btn btn-info"
                        onClick={() => handleViewDetails(order.orderId)}
                      >
                        View Details
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No orders found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination d-flex justify-content-center mt-4">
            <button
              className="btn btn-secondary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span className="mx-2">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>

      {/* Modal for viewing order details */}
      {selectedOrder && (
        <Modal
          isOpen={isDetailsModalOpen}
          onRequestClose={() => setDetailsModalOpen(false)}
          style={modalStyle}
          contentLabel="Order Details"
        >
          <h4>Order Details for Order ID: {selectedOrder.orderId}</h4>
          <p>
            <strong>Order Code:</strong> {selectedOrder.orderCode}
          </p>
          <p>
            <strong>Total:</strong> ${selectedOrder.total.toFixed(2)}
          </p>
          <p>
            <strong>Customer Name:</strong> {selectedOrder.customerName}
          </p>
          <p>
            <strong>Customer Phone:</strong> {selectedOrder.customerPhone}
          </p>
          <p>
            <strong>Customer Address:</strong> {selectedOrder.customerAddress}
          </p>
          <p>
            <strong>Order Status:</strong> {selectedOrder.orderStatus}
          </p>
          <p>
            <strong>Order Date:</strong>{" "}
            {new Date(selectedOrder.orderDate).toLocaleString()}
          </p>
          {selectedOrder.voucher && (
            <div className="voucher-details mt-4">
              <h5>Voucher Details</h5>
              <p>
                <strong>Voucher Code:</strong>{" "}
                {selectedOrder.voucher.voucherCode}
              </p>
              <p>
                <strong>Voucher Name:</strong>{" "}
                {selectedOrder.voucher.voucherName}
              </p>
              <p>
                <strong>Description:</strong>{" "}
                {selectedOrder.voucher.voucherDescription}
              </p>
              <p>
                <strong>Start Date:</strong>{" "}
                {new Date(selectedOrder.voucher.startDate).toLocaleDateString()}
              </p>
              <p>
                <strong>End Date:</strong>{" "}
                {new Date(selectedOrder.voucher.endDate).toLocaleDateString()}
              </p>
              <p>
                <strong>Value:</strong> {selectedOrder.voucher.value}%
              </p>
            </div>
          )}
          <h5>Order Details</h5>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Product</th>
                <th>Quantity</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {selectedOrder.orderDetails.map((item) => (
                <tr key={item.orderDetailId}>
                  <td>
                    <img
                      src={item.productVariant.image}
                      alt={item.productVariant.productVariantName}
                      width="50"
                    />
                    <span>{item.productVariant.productVariantName}</span>
                  </td>
                  <td>{item.quantity}</td>
                  <td>${item.price.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            className="btn btn-primary mt-3"
            onClick={() => setDetailsModalOpen(false)}
          >
            Close
          </button>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
};

export default memo(OrderPage);
