import { memo, useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./style.scss";
import { Sidebar } from "Components";
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { ROUTERS } from "Utils/router";
import instance from "Utils/axiosCustom";

const CategoryPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [categories, setCategories] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Fetch categories from the API on component mount or when search/filter changes
  useEffect(() => {
    const fetchCategories = async () => {
      setLoading(true); // Start loading indicator
      try {
        const response = await instance.get("category", {
          params: {
            categoryName: searchTerm || undefined,
            code: searchCode || undefined,
            status: searchStatus !== "" ? searchStatus === "true" : undefined,
            page: currentPage - 1, // API expects 0-based page
            size: 10,
          },
        });
        if (response.data && response.data.content) {
          const categoryData = response.data.content;
          setCategories(categoryData);
          setCurrentCategories(categoryData);
          setTotalPages(response.data.totalPages); // Set total pages
          setTotalElements(response.data.totalElements); // Set total elements
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching categories");
      } finally {
        setLoading(false); // End loading indicator
      }
    };

    fetchCategories();
  }, [currentPage, searchTerm, searchCode, searchStatus]);

  // Search Handler
  const handleSearch = () => {
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // Pagination Handlers
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage); // Update current page
    }
  };

  // Handle deleting category with confirmation
  // Handle deleting category with confirmation
  const handleDeleteCategory = async (categoryId) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete category ID ${categoryId}?`
    );
    if (confirmDelete) {
      try {
        await instance.delete(`category/${categoryId}`);
        // Refresh categories after deletion
        const updatedCategories = categories.filter(
          (cat) => cat.categoryId !== categoryId
        );
        setCategories(updatedCategories);
        setCurrentCategories(updatedCategories); // Update currentCategories as well
        alert("Category deleted successfully.");
      } catch (error) {
        alert("Error deleting category.");
      }
    }
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Categories</h3>
          {/* Create Category Button on the right */}
          <Link to={ROUTERS.ADMIN.CREATE_CATEGORIES}>
            <button className="btn btn-success">Create Category</button>
          </Link>
          {/* Search Input and Button */}
          <div className="mb-3 mt-4 d-flex">
            {/* Search by category name */}
            <input
              type="text"
              className="form-control-1"
              placeholder="Search by category name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Search by category name"
            />

            {/* Search by category code */}
            <input
              type="text"
              className="form-control-1 ms-3"
              placeholder="Search by code..."
              value={searchCode}
              onChange={(e) => setSearchCode(e.target.value)}
              aria-label="Search by code"
            />

            {/* Search by status */}
            <select
              value={searchStatus}
              onChange={(e) => setSearchStatus(e.target.value)}
              className="form-control-1 ms-3"
              aria-label="Search by status"
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>

            <button
              className="btn btn-primary button-search ms-3"
              onClick={handleSearch}
              aria-label="Search"
            >
              Search
            </button>
          </div>

          {/* Show loading message if data is being fetched */}
          {loading && (
            <div className="alert alert-info">Loading categories...</div>
          )}

          {/* Show error message if there's an error */}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Categories Table */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">CategoryID</th>
                <th scope="col">Category name</th>
                <th scope="col">Code</th>
                <th scope="col">Status</th>
                <th scope="col">Create datetime</th>
                <th scope="col">Update datetime</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentCategories.length > 0 ? (
                currentCategories.map((category) => (
                  <tr key={category.categoryId}>
                    <td>{category.categoryId}</td>
                    <td>{category.categoryName}</td>
                    <td>{category.code}</td>
                    <td>{category.status ? "Active" : "Inactive"}</td>
                    <td>{new Date(category.createdAt).toLocaleString()}</td>
                    <td>{new Date(category.updatedAt).toLocaleString()}</td>
                    <td>
                      <Link to={ROUTERS.ADMIN.CREATE_CATEGORIES}>
                        <button
                          className="btn btn-success"
                          aria-label="Edit category"
                        >
                          <IoCreateOutline />
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() =>
                          handleDeleteCategory(category.categoryId)
                        }
                        aria-label="Delete category"
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No categories found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-secondary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CategoryPage);
