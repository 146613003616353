import React, { useState, useEffect, memo } from "react";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { ProductCard } from "Components";
import instance from "Utils/axiosCustom";

const ProductsPage = () => {
  const location = useLocation();
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(1000000);
  const [sortOption, setSortOption] = useState("Giá thấp đến cao");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Số sản phẩm mỗi trang

  const categoryName = decodeURIComponent(
    new URLSearchParams(location.search).get("category") || ""
  );

  useEffect(() => {
    const fetchAllProducts = async () => {
      let allProducts = [];
      let pageNumber = 0;
      let totalPages = 1;

      try {
        while (pageNumber < totalPages) {
          const response = await instance.get(`product/active?page=${pageNumber}&size=10`);
          if (response.data && response.data.content) {
            allProducts = [...allProducts, ...response.data.content];
            totalPages = response.data.totalPages;
            pageNumber += 1;
          } else {
            console.error("Invalid API structure", response.data);
            break;
          }
        }
        setProducts(allProducts);
      } catch (error) {
        console.error("Lỗi khi lấy sản phẩm:", error);
      }
    };

    fetchAllProducts();
  }, []);

  useEffect(() => {
    const removeAccents = (str) => {
      return str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()
        .trim();
    };

    if (categoryName) {
      const filtered = products.filter((product) => {
        const productCategory = removeAccents(
          product.product.category.categoryName
        );
        const urlCategoryName = removeAccents(categoryName);
        return productCategory === urlCategoryName;
      });
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(products);
    }
  }, [categoryName, products]);

  const finalProducts = filteredProducts
    .filter((product) => {
      const isWithinPriceRange =
        product.price >= minPrice && product.price <= maxPrice;
      const matchesSearchQuery =
        product.product.productName &&
        product.product.productName
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      return isWithinPriceRange && matchesSearchQuery;
    })
    .sort((a, b) => {
      switch (sortOption) {
        case "Giá thấp đến cao":
          return a.price - b.price;
        case "Giá cao đến thấp":
          return b.price - a.price;
        default:
          return 0;
      }
    });

  // Tính toán sản phẩm để hiển thị cho trang hiện tại
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = finalProducts.slice(indexOfFirstItem, indexOfLastItem);

  // Chuyển sang trang tiếp theo
  const nextPage = () => {
    if (currentPage < Math.ceil(finalProducts.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Quay lại trang trước đó
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Breadcrumb name="Products" />
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-12 col-sm-12 col-xs-12">
            <div className="sidebar">
              <div className="sidebar__item">
                <h2>Tìm kiếm</h2>
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  placeholder="Tìm sản phẩm..."
                />
              </div>
              <div className="sidebar__item">
                <h2>Mức giá</h2>
                <div className="price-range-wrap">
                  <div>
                    <p>Từ:</p>
                    <input
                      type="number"
                      min={0}
                      value={minPrice}
                      onChange={(e) => setMinPrice(Number(e.target.value))}
                    />
                  </div>
                  <div>
                    <p>Đến:</p>
                    <input
                      type="number"
                      min={0}
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(Number(e.target.value))}
                    />
                  </div>
                </div>
              </div>
              <div className="sidebar__item">
                <h2>Sắp xếp</h2>
                <div className="tags">
                  {["Giá thấp đến cao", "Giá cao đến thấp"].map((item, key) => (
                    <div
                      className={`tag ${sortOption === item ? "active" : ""}`}
                      key={key}
                      onClick={() => setSortOption(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-12 col-sm-12 col-xs-12">
            <div className="row">
              {currentItems.map((item, key) => (
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12" key={key}>
                  <ProductCard
                    img={item.product.productImages[0]?.imageUrl}
                    name={item.product.productName}
                    price={item.price}
                    id={item.productVariantId}
                  />
                </div>
              ))}
            </div>
            <div className="pagination">
              <button onClick={prevPage} disabled={currentPage === 1}>
                Previous
              </button>
              <span>
                Page {currentPage} of {Math.ceil(finalProducts.length / itemsPerPage)}
              </span>
              <button
                onClick={nextPage}
                disabled={currentPage === Math.ceil(finalProducts.length / itemsPerPage)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ProductsPage);
