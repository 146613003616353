import React, { useState, useEffect, memo } from "react";
import "react-slick";
import "./style.scss";
import { ProductCard } from "Components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Slider from "Components/Slider";
import instance from "Utils/axiosCustom";

const HomePage = () => {
  const [brands, setBrands] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Số sản phẩm mỗi trang

  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
    desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
    tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
    mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
  };

  const bannerSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await instance.get("brand/active");
        if (response.data && response.data.content) {
          setBrands(response.data.content);
          setSelectedBrand(response.data.content[0]?.brandName);
        } else {
          console.error("Invalid API structure", response.data);
        }
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };
    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchAllProducts = async () => {
      let allProducts = [];
      let pageNumber = 0;
      let totalPages = 1;

      try {
        while (pageNumber < totalPages) {
          const response = await instance.get(`product/active?page=${pageNumber}&size=10`);
          if (response.data && response.data.content) {
            allProducts = [...allProducts, ...response.data.content];
            totalPages = response.data.totalPages;
            pageNumber += 1;
          } else {
            console.error("Invalid API structure", response.data);
            break;
          }
        }
        setAllProducts(allProducts);
      } catch (error) {
        console.error("Lỗi khi lấy sản phẩm:", error);
      }
    };

    fetchAllProducts();
  }, []);

  useEffect(() => {
    if (selectedBrand) {
      const filtered = allProducts.filter(
        (item) => item.product.brand.brandName === selectedBrand
      );
      setFilteredProducts(filtered);
      setCurrentPage(1); // Reset trang về 1 khi thay đổi thương hiệu
    }
  }, [selectedBrand, allProducts]);

  // Tính toán sản phẩm để hiển thị cho trang hiện tại
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredProducts.slice(indexOfFirstItem, indexOfLastItem);

  // Chuyển sang trang tiếp theo
  const nextPage = () => {
    if (currentPage < Math.ceil(filteredProducts.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Quay lại trang trước đó
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const renderFeaturedProducts = () => {
    return (
      <div className="row">
        {currentItems.map((item, key) => (
          <div
            className="col-lg-3 col-md-4 col-sm-6 col-xs-12 product-card"
            key={key}
          >
            <ProductCard
              img={item.product.productImages[0]?.imageUrl}
              name={item.product.productName}
              price={item.price}
              id={item.productVariantId}
            />
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <section className="hero-banner">
        <Slider />
      </section>

      <div className="container container__categories_slider">
        <Carousel responsive={responsive} className="categories_slider">
          {brands && brands.length > 0 ? (
            brands.map((item, key) => (
              <div
                className="categories_slider_item"
                key={key}
                onClick={() => setSelectedBrand(item.brandName)}
                style={{ cursor: "pointer", textAlign: "center" }}
              >
                <img
                  src={`/${item.brandName.toLowerCase()}.png`}
                  alt={item.brandName}
                  style={{
                    maxWidth: "120px",
                    maxHeight: "120px",
                    borderRadius: "50%",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                />
                <p style={{ marginTop: "10px" }}>{item.brandName}</p>
              </div>
            ))
          ) : (
            <p>Loading brands...</p>
          )}
        </Carousel>
      </div>

      <div className="container">
        <div className="featured" id="featured">
          <div className="section-title">
            <h2>Featured Products from {selectedBrand}</h2>
          </div>
          {filteredProducts && filteredProducts.length > 0 ? (
            <>
              {renderFeaturedProducts()}
              <div className="pagination">
                <button onClick={prevPage} disabled={currentPage === 1}>
                  Previous
                </button>
                <span>
                  Page {currentPage} of {Math.ceil(filteredProducts.length / itemsPerPage)}
                </span>
                <button
                  onClick={nextPage}
                  disabled={currentPage === Math.ceil(filteredProducts.length / itemsPerPage)}
                >
                  Next
                </button>
              </div>
            </>
          ) : (
            <p>No products available</p>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(HomePage);
