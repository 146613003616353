import { memo } from "react";
import { generatePath, Link } from "react-router-dom";
import { formatter } from "Utils/formatter";
import "./style.scss";
import { ROUTERS } from "Utils/router";


const ProductCard = ({ img, name, price, id }) => {
    return (
        <>
            <div className="featured__item pl-pr-10">
                <div
                    className="featured__item__pic"
                    style={{
                        backgroundImage: `url(${img})`,
                    }}
                >
                    <ul className="featured__item__pic__hover">
                        <li>
                            <Link to={generatePath(ROUTERS.USER.DETAIL, { id: String(id) })}>
                                <img src={`${process.env.PUBLIC_URL}/eye.png`} alt="View Details" style={{ width: '24px', height: '24px' }} />
                            </Link>
                        </li>
                        
                    </ul>
                </div>
                <div className="featured__item__text">
                    <h6>{name}</h6>
                    <h5>{formatter(price)}</h5>
                </div>
            </div>
        </>
    );
};


export default memo(ProductCard);
