import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "Components";
import instance from "Utils/axiosCustom"; // Tùy chỉnh axios đã cấu hình
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import "./style.scss"; // Style cho trang

const CustomTypePage = () => {
  // Các state lưu trữ dữ liệu và trạng thái trang
  const [customTypeName, setCustomTypeName] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [status, setStatus] = useState("");
  const [customTypes, setCustomTypes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Fetch custom types từ API khi trang được mount hoặc khi có thay đổi search/filter
  useEffect(() => {
    const fetchCustomTypes = async () => {
      setLoading(true); // Bắt đầu hiển thị trạng thái loading
      try {
        const response = await instance.get("/customType", {
          params: {
            customTypeName: customTypeName || undefined,
            minPrice: minPrice || undefined,
            maxPrice: maxPrice || undefined,
            status: status !== "" ? status === "true" : undefined,
            page: currentPage - 1, // API bắt đầu page từ 0
            size: 10,
          },
        });

        if (response.data && response.data.content) {
          setCustomTypes(response.data.content); // Lưu dữ liệu custom types
          setTotalPages(response.data.totalPages); // Tổng số trang
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching custom types");
      } finally {
        setLoading(false); // Kết thúc trạng thái loading
      }
    };

    fetchCustomTypes();
  }, [currentPage, customTypeName, minPrice, maxPrice, status]);

  // Search Handler
  const handleSearch = () => {
    setCurrentPage(1); // Reset trang về 1 khi có thay đổi tìm kiếm
  };

  // Pagination Handlers
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage); // Thay đổi trang hiện tại
    }
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Custom Types</h3>
          {/* Tạo Custom Type Button */}
          <Link to="/admin/custom-types/create">
            <button className="btn btn-success">Create Custom Type</button>
          </Link>

          {/* Input tìm kiếm */}
          <div className="mb-3 mt-4 d-flex">
            {/* Tìm kiếm theo customTypeName */}
            <input
              type="text"
              className="form-control-1"
              placeholder="Custom Type Name..."
              value={customTypeName}
              onChange={(e) => setCustomTypeName(e.target.value)}
              aria-label="Custom Type Name"
            />

            {/* Tìm kiếm theo minPrice */}
            <input
              type="number"
              className="form-control-1 ms-3"
              placeholder="Min Price..."
              value={minPrice}
              onChange={(e) => setMinPrice(e.target.value)}
              aria-label="Min Price"
            />

            {/* Tìm kiếm theo maxPrice */}
            <input
              type="number"
              className="form-control-1 ms-3"
              placeholder="Max Price..."
              value={maxPrice}
              onChange={(e) => setMaxPrice(e.target.value)}
              aria-label="Max Price"
            />

            {/* Tìm kiếm theo status */}
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="form-control-1 ms-3"
              aria-label="Status"
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>

            <button
              className="btn btn-primary button-search ms-3"
              onClick={handleSearch}
              aria-label="Search"
            >
              Search
            </button>
          </div>

          {/* Hiển thị trạng thái loading */}
          {loading && (
            <div className="alert alert-info">Loading custom types...</div>
          )}

          {/* Hiển thị lỗi nếu có */}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Bảng hiển thị danh sách custom types */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Custom Type ID</th>
                <th scope="col">Name</th>
                <th scope="col">Description</th>
                <th scope="col">Price</th>
                <th scope="col">Status</th>
                <th scope="col">Create datetime</th>
                <th scope="col">Update datetime</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {customTypes.length > 0 ? (
                customTypes.map((customType) => (
                  <tr key={customType.customTypeId}>
                    <td>{customType.customTypeId}</td>
                    <td>{customType.customTypeName}</td>
                    <td>{customType.description}</td>
                    <td>{customType.price.toLocaleString()}</td>
                    <td>{customType.status ? "Active" : "Inactive"}</td>
                    <td>{new Date(customType.createdAt).toLocaleString()}</td>
                    <td>{new Date(customType.updatedAt).toLocaleString()}</td>
                    <td>
                      <Link
                        to={`/admin/custom-types/edit/${customType.customTypeId}`}
                      >
                        <button
                          className="btn btn-success"
                          aria-label="Edit custom type"
                        >
                          <IoCreateOutline />
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        aria-label="Delete custom type"
                        // Xử lý sự kiện delete sau
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="text-center">
                    No custom types found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Phân trang */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-secondary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(CustomTypePage);
