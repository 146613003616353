import { memo, useState } from "react";
import "./style.scss";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { IoTrendingUp, IoTrendingDown } from "react-icons/io5";

const DashboardBox = ({ color = ["#fff", "#000"], icon, grow = false, title = "Title", count = "0" }) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => setDropdownOpen((prev) => !prev);

    return (
        <div className="dashboardBox" style={{
            backgroundImage: `linear-gradient(to right, ${color[0]}, ${color[1]})`
        }}>
            <span className="chart">
                {grow ? <IoTrendingUp /> : <IoTrendingDown />}
            </span>

            <div className="d-flex w-100">
                <div className="col1">
                    <h4 className="text-white">{title}</h4>
                    <span className="text-white">{count}</span>
                </div>

                <div className="ml-auto">
                    <span className="icon">{icon}</span>
                </div>
            </div>

            <div className="d-flex align-items-center w-100 bottomEle">
                <h6 className="text-white mb-0 mt-0">Last Month</h6>
                <span className="ml-auto toggleIcon" onClick={toggleDropdown}>
                    <HiOutlineDotsVertical />
                </span>

                {dropdownOpen && (
                    <div className="dropdownMenu">
                        <ul>
                            <li onClick={() => console.log('Last Month clicked')}>Last Month</li>
                            <li onClick={() => console.log('Last Year clicked')}>Last Year</li>
                            <li onClick={() => console.log('Last Week clicked')}>Last Week</li>
                        </ul>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(DashboardBox);
