import { memo } from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { ROUTERS } from "Utils/router";

const Breadcrumb = (props) => {
    return <div className="breadcrumb">
        <div className="breadcrumb__text">
            <h2>SmashElite</h2>
            <div className="breadcrumb__option">
                <ul>
                    <li className="link">
                        <Link to={ROUTERS.USER.HOME}>Home</Link>
                    </li>
                    <li>
                        {props.name}
                    </li>
                </ul>
            </div>
        </div>
    </div>
};

export default memo(Breadcrumb);