import { memo } from "react";
import "./style.scss";
import { Sidebar, Dashboard } from "Components";

const DashboardPage = () => {
    return (
        <div className="main d-flex">
            {/* Sidebar Section */}
            <div className="sidebarWrapper">
                <Sidebar />
            </div>

            {/* Content Section */}
            <div className="content">
                <Dashboard />
            </div>
        </div>
    );
};

export default memo(DashboardPage);
