 import React, { memo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { ROUTERS } from "Utils/router";
import instance from "Utils/axiosCustom";
import { jwtDecode } from "jwt-decode";
import logoImg from "../../../../Assets/users/images/logo/logo.jpg";

const LoginPage = () => {
  const [form, setForm] = useState({
    email: "SmashElite@gmail.com",
    password: "@12345678",
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  };

  const handleSubmit = async () => {
    if (form.email && form.password) {
      try {
        const response = await instance.post("auth/login", {
          email: form.email,
          password: form.password,
        });
        if (response.status === 200) {
          const token = response.data.access_token;
          const user = jwtDecode(token);
          console.log("Đăng nhập thành công", response.data);
          localStorage.setItem("access_token", response.data.access_token);
          localStorage.setItem(
            "userInfo",
            JSON.stringify(response.data.userInfo)
          );
          if (user.role === "ADMIN") {
            navigate(ROUTERS.ADMIN.DASHBOARD);
          } else {
            navigate(ROUTERS.USER.HOME);
          }
        }
      } catch (error) {
        console.error("Lỗi khi đăng nhập:", error);
        setError("Đăng nhập thất bại. Vui lòng kiểm tra lại thông tin.");
      }
    } else {
      setError("Vui lòng điền đầy đủ thông tin.");
    }
  };

  return (
    <div className="login-container">
      <img src={logoImg} alt="Logo" className="login-logo" />

      <div className="login-box">
        <h1 className="login-title">Đăng nhập</h1>
        <div className="login-form">
          {error && <p className="error-message">{error}</p>}

          <div className="form-group">
            <label htmlFor="email">Địa chỉ Email</label>
            <input
              type="email"
              id="email"
              value={form.email}
              onChange={handleChange}
              placeholder="Nhập địa chỉ email"
              className="form-control"
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Mật khẩu</label>
            <input
              type="password"
              id="password"
              value={form.password}
              onChange={handleChange}
              placeholder="Nhập mật khẩu"
              className="form-control"
            />
          </div>

          <button
            className="login-button"
            onClick={handleSubmit}
            disabled={!form.email || !form.password}
          >
            Đăng nhập
          </button>

          <div className="links-container">
            <p>
              Bạn chưa có tài khoản?{" "}
              <Link to={ROUTERS.USER.REGISTER}>Đăng ký</Link>
            </p>
            <p>
              Quên mật khẩu?{" "}
              <Link to={ROUTERS.USER.RESET}>Lấy lại mật khẩu</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(LoginPage);
