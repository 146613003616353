import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "Components";
import instance from "Utils/axiosCustom"; // Tùy chỉnh axios đã cấu hình
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import "./style.scss"; // Style cho trang

const SizePage = () => {
  // Các state lưu trữ dữ liệu và trạng thái trang
  const [sizeValue, setSizeValue] = useState("");
  const [status, setStatus] = useState("");
  const [sizes, setSizes] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Fetch sizes từ API khi trang được mount hoặc khi có thay đổi search/filter
  useEffect(() => {
    const fetchSizes = async () => {
      setLoading(true); // Bắt đầu hiển thị trạng thái loading
      try {
        const response = await instance.get("/sizes", {
          params: {
            sizeValue: sizeValue || undefined,
            status: status !== "" ? status === "true" : undefined,
            page: currentPage - 1, // API bắt đầu page từ 0
            size: 10,
          },
        });

        if (response.data && response.data.content) {
          setSizes(response.data.content); // Lưu dữ liệu size
          setTotalPages(response.data.totalPages); // Tổng số trang
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching sizes");
      } finally {
        setLoading(false); // Kết thúc trạng thái loading
      }
    };

    fetchSizes();
  }, [currentPage, sizeValue, status]);

  // Search Handler
  const handleSearch = () => {
    setCurrentPage(1); // Reset trang về 1 khi có thay đổi tìm kiếm
  };

  // Pagination Handlers
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage); // Thay đổi trang hiện tại
    }
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Sizes</h3>
          {/* Tạo Size Button */}
          <Link to="/admin/sizes/create">
            <button className="btn btn-success">Create Size</button>
          </Link>

          {/* Input tìm kiếm */}
          <div className="mb-3 mt-4 d-flex">
            {/* Tìm kiếm theo sizeValue */}
            <input
              type="text"
              className="form-control-1"
              placeholder="Size Value..."
              value={sizeValue}
              onChange={(e) => setSizeValue(e.target.value)}
              aria-label="Size Value"
            />

            {/* Tìm kiếm theo status */}
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="form-control-1 ms-3"
              aria-label="Status"
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>

            <button
              className="btn btn-primary button-search ms-3"
              onClick={handleSearch}
              aria-label="Search"
            >
              Search
            </button>
          </div>

          {/* Hiển thị trạng thái loading */}
          {loading && <div className="alert alert-info">Loading sizes...</div>}

          {/* Hiển thị lỗi nếu có */}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Bảng hiển thị danh sách sizes */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Size ID</th>
                <th scope="col">Value</th>
                <th scope="col">Status</th>
                <th scope="col">Create datetime</th>
                <th scope="col">Update datetime</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {sizes.length > 0 ? (
                sizes.map((size) => (
                  <tr key={size.sizeId}>
                    <td>{size.sizeId}</td>
                    <td>{size.sizeValue}</td>
                    <td>{size.status ? "Active" : "Inactive"}</td>
                    <td>{new Date(size.createdAt).toLocaleString()}</td>
                    <td>{new Date(size.updatedAt).toLocaleString()}</td>
                    <td>
                      <Link to={`/admin/sizes/edit/${size.sizeId}`}>
                        <button
                          className="btn btn-success"
                          aria-label="Edit size"
                        >
                          <IoCreateOutline />
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        aria-label="Delete size"
                        // Xử lý sự kiện delete sau
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">
                    No sizes found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Phân trang */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-secondary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SizePage);
