export const ROUTERS = {
  ADMIN: {
    DASHBOARD: "/Dashboard",
    USERS: "/Users",
    CREATE_USERS: "/Create/Users",
    ORDERS: "/Orders",
    CREATE_ORDERS: "/Create/Orders",
    PRODUCT: "/Products",
    CREATE_PRODUCT: "/Create/Products",
    CATEGORIES: "/Categories",
    CREATE_CATEGORIES: "/Create/Categories",
    UPDATE_CATEGORIES: "/UPDATE/Categories",
    BRAND: "/Brand",
    COLOR: "/Color",
    CUSTOMTYPE: "/CustomType",
    SIZE: "/Size",
    VOUCHER: "/Voucher",

    // SETTING: "/Settings",
    // REPORT: "/Reports",
  },
  USER: {
    HOME: "/",
    LOGIN: "/Login",
    REGISTER: "/Register",
    RESET: "/Reset",
    RESETPASSWORD: "/ResetPassword",
    PROFILE: "/Profile",
    PRODUCTS: "/Product", //Truyền ID
    DETAIL: "/Detail/Product/:id",
    ORDERHISTORY: "/OrderHistory",
    CUSTOMIZE: "/Customize",
    CUSTOMPRODUCT: "/Custom/Product",
    PAYMENT: "/Payment/Product",
    ABOUT: "/About",
    CART: "/Cart",
  },
};
