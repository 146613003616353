import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { ROUTERS } from "Utils/router";
import logoImg from "../../../../Assets/users/images/logo/logo.jpg";

const ResetPage = () => {
  const [email, setEmail] = useState("");
  const [captchaInput, setCaptchaInput] = useState("");
  const [captcha, setCaptcha] = useState("AbC12"); // Example generated captcha

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleCaptchaChange = (e) => {
    setCaptchaInput(e.target.value);
  };

  const handleSubmit = () => {
    if (email && captchaInput === captcha) {
      // Redirect or handle the reset password request
      console.log("Captcha validated and form submitted");
    } else {
      console.log("Invalid captcha or missing email");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img src={logoImg} alt="Logo" className="login-logo" />
      <div className="reset-container">
        <h1 className="reset-title">Reset Password</h1>
        <div className="reset-form">
          {/* Email Address */}
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              placeholder="Enter your email"
              className="form-control"
            />
          </div>

          {/* Captcha Code Display */}
          <div className="form-group captcha-display">
            <label htmlFor="captcha-display">Captcha Code</label>
            <div className="captcha-code">
              {/* This can be an image or randomly generated code */}
              <span>{captcha}</span>{" "}
              {/* Replace with actual captcha generation */}
            </div>
          </div>

          {/* Captcha Code Input */}
          <div className="form-group">
            <label htmlFor="captcha">Enter Captcha Code</label>
            <input
              type="text"
              id="captcha"
              value={captchaInput}
              onChange={handleCaptchaChange}
              placeholder="Enter the captcha code"
              className="form-control"
            />
          </div>

          {/* Continue Button */}
          <button
            className="reset-button"
            onClick={handleSubmit}
            disabled={!email || captchaInput !== captcha}
          >
            Continue
          </button>

          {/* Option to Sign in if already registered */}
          <div className="signin-option">
            <p>Remembered your password?</p>
            <Link to={ROUTERS.USER.LOGIN}>Sign in</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ResetPage);
