import { useState, useEffect, memo } from "react";
import "./style.scss";
import DashboardBox from "Components/DashboardBox";
import { BsFillPersonFill } from "react-icons/bs";
import { TiShoppingCart } from "react-icons/ti";
import { IoBagSharp, IoStarSharp } from "react-icons/io5";
import instance from "Utils/axiosCustom";

const Dashboard = () => {
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [orders, setOrders] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [totalSales, setTotalSales] = useState(0);
    const [totalOrders, setTotalOrders] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0); // Total users count
    const [totalProductsSold, setTotalProductsSold] = useState(0); // Total products sold count
    const productsPerPage = 10;

    // Fetch orders from API and calculate total sales, order count, and total products sold
    useEffect(() => {
        const fetchOrders = async () => {
            setLoading(true);
            try {
                const response = await instance.get("order", {
                    params: {
                        page: currentPage - 1,
                        size: productsPerPage,
                        name: searchTerm, // Filter if any search term is entered
                    },
                });

                if (response.data && response.data.content) {
                    const ordersData = response.data.content;

                    // Set orders for display in the table
                    setOrders(ordersData);

                    // // Calculate total sales by summing up the `total` of each order
                    // const sales = ordersData.reduce((acc, order) => acc + order.total, 0);
                    // setTotalSales(sales);

                    // // Calculate total products sold by summing up the quantity in each order's orderDetails
                    // const productsSold = ordersData.reduce((acc, order) => {
                    //     return acc + order.orderDetails.reduce((subAcc, detail) => subAcc + detail.quantity, 0);
                    // }, 0);
                    // setTotalProductsSold(productsSold);

                    // Set total orders count from the API response
                    setTotalOrders(response.data.totalElements);

                    // Set total pages for pagination
                    setTotalPages(response.data.totalPages);
                } else {
                    setErrorMessage("Invalid API structure");
                }
            } catch (error) {
                setErrorMessage("Error fetching orders");
            } finally {
                setLoading(false);
            }
        };
        fetchOrders();
    }, [currentPage, searchTerm]);
// Hàm tính tổng doanh thu trên tất cả các trang
const fetchTotalSales = async () => {
    let currentPage = 0;
    let totalSales = 0;
    let hasMorePages = true;

    try {
        while (hasMorePages) {
            const response = await instance.get("order", {
                params: {
                    page: currentPage,
                    size: productsPerPage,
                },
            });

            if (response.data && response.data.content) {
                const ordersData = response.data.content;

                // Cộng tổng tiền từ trang hiện tại vào tổng doanh thu
                const sales = ordersData.reduce((acc, order) => acc + order.total, 0);
                totalSales += sales;

                // Kiểm tra xem còn trang nào khác không
                hasMorePages = currentPage < response.data.totalPages - 1;
                currentPage++;
            } else {
                setErrorMessage("Invalid API structure");
                break;
            }
        }

        setTotalSales(totalSales);
    } catch (error) {
        setErrorMessage("Error fetching total sales");
    }
};

// Gọi hàm này khi component được mount để cập nhật doanh thu trên toàn bộ trang
useEffect(() => {
    fetchTotalSales();
}, []);
// Hàm tính tổng số sản phẩm bán trên tất cả các trang
const fetchTotalProductsSold = async () => {
    let currentPage = 0;
    let totalProductsSold = 0;
    let hasMorePages = true;

    try {
        while (hasMorePages) {
            const response = await instance.get("order", {
                params: {
                    page: currentPage,
                    size: productsPerPage,
                },
            });

            if (response.data && response.data.content) {
                const ordersData = response.data.content;

                // Cộng số lượng sản phẩm từ trang hiện tại vào tổng
                const productsSold = ordersData.reduce((acc, order) => {
                    return acc + order.orderDetails.reduce((subAcc, detail) => subAcc + detail.quantity, 0);
                }, 0);
                totalProductsSold += productsSold;

                // Kiểm tra xem còn trang nào khác không
                hasMorePages = currentPage < response.data.totalPages - 1;
                currentPage++;
            } else {
                setErrorMessage("Invalid API structure");
                break;
            }
        }

        setTotalProductsSold(totalProductsSold);
    } catch (error) {
        setErrorMessage("Error fetching total products sold");
    }
};

// Gọi hàm này khi component được mount để cập nhật số lượng sản phẩm bán trên toàn bộ trang
useEffect(() => {
    fetchTotalProductsSold();
}, []);

    // Fetch total users from the users API
    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await instance.get("/users", {
                    params: {
                        page: 0, // Fetch only the first page to get the count
                        size: 1,
                    },
                });
                if (response.data && response.data.totalElements) {
                    setTotalUsers(response.data.totalElements);
                } else {
                    setErrorMessage("Invalid API structure for users");
                }
            } catch (error) {
                setErrorMessage("Error fetching users");
            }
        };
        fetchUsers();
    }, []);

    // Handle pagination
    const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

    // Handle search
    const handleSearch = () => {
        setCurrentPage(1); // Reset to the first page on new search
    };

    return (
        <div className="right-content w-100">
            {/* Dashboard boxes */}
            <div className="row dashboardBoxWrapperRow">
                <div className="col-md-8">
                    <div className="dashboardBoxWrapper d-flex">
                        <DashboardBox
                            color={["#1da256", "#48d483"]}
                            icon={<BsFillPersonFill />}
                            grow
                            title="Total Users"
                            count={totalUsers.toLocaleString()}
                        />
                        <DashboardBox
                            color={["#c012e2", "#eb64fe"]}
                            icon={<TiShoppingCart />}
                            title="Orders"
                            count={totalOrders.toLocaleString()}
                        />
                        <DashboardBox
                            color={["#2c78e5", "#60aff5"]}
                            icon={<IoBagSharp />}
                            title="Revenue"
                            count={`$${totalSales.toLocaleString()}`}
                        />
                        <DashboardBox
                            color={["#e1950e", "#f3cd29"]}
                            icon={<IoStarSharp />}
                            grow
                            title="Products Sold"
                            count={totalProductsSold.toLocaleString()}
                        />
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="box graphBox">
                        <div className="d-flex align-items-center w-100 bottomEle">
                            <h4>Total Sales</h4>
                        </div>
                        <h3 className="text-white fw-bold">${totalSales.toLocaleString()}</h3>
                        <p> Last month</p>
                    </div>
                </div>
            </div>

            {/* Best Selling Products section */}
            <div className="card shadow border-0 p-3 mt-4">
                <h3 className="hd">Best Selling Products</h3>

                {/* Search Input and Button */}
                <div className="mb-3 mt-4 input-search d-flex">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search for products..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className="btn btn-primary button-search ms-3" onClick={handleSearch}>
                        Search
                    </button>
                </div>

                {/* Orders Table */}
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th scope="col">Id</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Order Date</th>
                            <th scope="col">Price</th>
                            <th scope="col">Quantity Sold</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            <tr>
                                <td colSpan="5">Loading...</td>
                            </tr>
                        ) : orders.length > 0 ? (
                            orders.map((order) => (
                                <tr key={order.orderId}>
                                    <th scope="row">{order.orderId}</th>
                                    <td>{order.orderDetails[0]?.productVariant?.productVariantName || "N/A"}</td>
                                    <td>{new Date(order.orderDate).toLocaleDateString() || "N/A"}</td>
                                    <td>{order.orderDetails[0]?.price?.toLocaleString() || "N/A"}</td>
                                    <td>{order.orderDetails[0]?.quantity || "N/A"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="5">{errorMessage || "No products found"}</td>
                            </tr>
                        )}
                    </tbody>
                </table>

                {/* Pagination Controls */}
                {orders.length > 0 && (
                    <div className="d-flex justify-content-between">
                        <button
                            className="btn btn-primary"
                            disabled={currentPage === 1}
                            onClick={handlePrevPage}
                        >
                            Previous
                        </button>
                        <span>Page {currentPage} of {totalPages}</span>
                        <button
                            className="btn btn-primary"
                            disabled={currentPage === totalPages}
                            onClick={handleNextPage}
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default memo(Dashboard);
