import React, { useState, useEffect, memo } from "react";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";
import { formatter } from "Utils/formatter";
import instance from "Utils/axiosCustom";
const ShoppingCartPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [showOrderModal, setShowOrderModal] = useState(false); // Show/hide modal
  const [checkoutUrl, setCheckoutUrl] = useState(""); // State để lưu checkoutUrl từ response

  const [customerInfo, setCustomerInfo] = useState({
    customerId: "",
    customerName: "",
    customerPhone: "",
    customerAddress: "",
    voucherId: null,
    voucherName: "",
    voucherValue: 0,
    note: null, // Note is always set to null
  });

  // const instance = axios.create({
  //     baseURL: 'https://joint-socially-pipefish.ngrok-free.app/api/v1/',
  //     withCredentials: true,
  //     headers: {
  //         "Access-Control-Allow-Origin": "*",
  //         "ngrok-skip-browser-warning": "true",
  //     },
  // });

  useEffect(() => {
    // Fetch cart items
    const fetchCartItems = async () => {
      try {
        const storedUserInfo = localStorage.getItem("userInfo");
        if (storedUserInfo) {
          const userInfo = JSON.parse(storedUserInfo);
          const customerId = userInfo.usersID;

          setCustomerInfo((prev) => ({
            ...prev,
            customerId: customerId,
            customerName: userInfo.username, // Set customerName from userInfo
            customerPhone: userInfo.phone || "", // Set customerPhone if available
            customerAddress: userInfo.address || "", // Set customerAddress if available
          }));

          const response = await instance.get(`carts/${customerId}`);
          if (response.data) {
            const cartData = response.data.map((item) => {
              const imageUrl =
                item.productVariant.productImages.length > 0
                  ? item.productVariant.productImages[0].imageUrl
                  : null;

              return {
                id: item.cartID,
                name: item.productVariant.productName,
                price: item.productVariant.price,
                quantity: item.quantity,
                image: imageUrl,
              };
            });
            setCartItems(cartData);
          } else {
            console.error("Invalid API structure", response.data);
          }
        }
      } catch (error) {
        console.error("Lỗi khi lấy giỏ hàng:", error);
      }
    };

    // Fetch active vouchers and apply if valid
    const fetchActiveVoucher = async () => {
      try {
        const today = new Date().toISOString().split("T")[0];
        const response = await instance.get("/voucher/active");

        const activeVoucher = response.data.content.find((voucher) => {
          return today >= voucher.startDate && today <= voucher.endDate;
        });

        if (activeVoucher) {
          setCustomerInfo((prev) => ({
            ...prev,
            voucherId: activeVoucher.voucherId,
            voucherName: activeVoucher.voucherName,
            voucherValue: activeVoucher.value,
          }));
        }
      } catch (error) {
        console.error("Lỗi khi lấy voucher:", error);
      }
    };

    fetchCartItems();
    fetchActiveVoucher();
  }, []);

  const handleQuantityChange = (id, value) => {
    const updatedItems = cartItems.map((item) =>
      item.id === id
        ? {
            ...item,
            quantity: Number(value),
          }
        : item
    );
    setCartItems(updatedItems);
  };

  const handleRemoveItem = (id) => {
    const filteredItems = cartItems.filter((item) => item.id !== id);
    setCartItems(filteredItems);
  };

  const calculateTotal = () => {
    const total = cartItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );
    if (customerInfo.voucherValue > 0) {
      const discount = (total * customerInfo.voucherValue) / 100;
      return total - discount;
    }
    return total;
  };

  const handlePlaceOrderClick = () => {
    setShowOrderModal(true); // Show modal when the button is clicked
  };

  const handleOrderSubmit = async () => {
    const totalPrice = calculateTotal();
    const orderPayload = {
      customerId: customerInfo.customerId, // Lấy từ state
      customerName: customerInfo.customerName,
      customerPhone: customerInfo.customerPhone,
      customerAddress: customerInfo.customerAddress,
      voucherId: customerInfo.voucherId, // Ensure voucherId is included
      note: null, // Note is always null
      totalPrice: totalPrice, // Add totalPrice to payload
    };

    try {
      const response = await instance.post(
        "/payOS/createOrderQR",
        orderPayload
      );
      console.log("Đặt hàng thành công:", response.data);
      const checkoutUrlFromResponse = response.data?.data?.checkoutUrl;
      if (checkoutUrlFromResponse) {
        setCheckoutUrl(checkoutUrlFromResponse);
        // Chuyển hướng người dùng tới checkoutUrl
        window.location.href = checkoutUrlFromResponse;
      }
      setShowOrderModal(false); // Close modal after successful order
    } catch (error) {
      console.error("Lỗi khi đặt hàng:", error);
    }
  };

  return (
    <>
      <Breadcrumb name="Giỏ hàng" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Tên</th>
                  <th scope="col">Giá</th>
                  <th scope="col">Số lượng</th>
                  <th scope="col">Thành tiền</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {cartItems.length > 0 ? (
                  cartItems.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr>
                        <td className="d-flex align-items-center justify-content-center">
                          <div className="product-name">
                            {item.image && (
                              <img
                                src={item.image}
                                alt={item.name}
                                style={{ width: "50px", marginRight: "10px" }}
                              />
                            )}
                            <span>{item.name}</span>
                          </div>
                        </td>
                        <td className="d-flex align-items-center justify-content-center">
                          {formatter(item.price)}
                        </td>
                        <td className="d-flex align-items-center justify-content-center">
                          <input
                            type="number"
                            value={item.quantity}
                            min="1"
                            onChange={(e) =>
                              handleQuantityChange(item.id, e.target.value)
                            }
                            className="form-control"
                            style={{ width: "80px" }}
                          />
                        </td>
                        <td className="d-flex align-items-center justify-content-center">
                          {formatter(item.price * item.quantity)}
                        </td>
                        <td className="d-flex align-items-center justify-content-center">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => handleRemoveItem(item.id)}
                          >
                            X
                          </button>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      Giỏ hàng trống
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            <div className="text-end">
              <h5>Tổng cộng: {formatter(calculateTotal())} VND</h5>
              {customerInfo.voucherName && (
                <p>
                  Voucher: {customerInfo.voucherName} - Giảm{" "}
                  {customerInfo.voucherValue}%
                </p>
              )}
              <button
                onClick={handlePlaceOrderClick}
                className="btn btn-primary"
              >
                Đặt Hàng
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to input customer info */}
      {showOrderModal && (
        <div className="custom-modal">
          <h5>Nhập Thông Tin Đặt Hàng</h5>
          <form>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Customer Name"
                value={customerInfo.customerName}
                onChange={(e) =>
                  setCustomerInfo({
                    ...customerInfo,
                    customerName: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Customer Phone"
                value={customerInfo.customerPhone}
                onChange={(e) =>
                  setCustomerInfo({
                    ...customerInfo,
                    customerPhone: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                placeholder="Customer Address"
                value={customerInfo.customerAddress}
                onChange={(e) =>
                  setCustomerInfo({
                    ...customerInfo,
                    customerAddress: e.target.value,
                  })
                }
              />
            </div>
            <button
              type="button"
              onClick={handleOrderSubmit}
              className="btn btn-primary"
            >
              Xác Nhận Đặt Hàng
            </button>
            <button
              type="button"
              onClick={() => setShowOrderModal(false)}
              className="btn btn-secondary"
            >
              Đóng
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default memo(ShoppingCartPage);
