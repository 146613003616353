import { memo, useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import "./style.scss";
import { Sidebar } from "Components";
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { ROUTERS } from "Utils/router";
import instance from "Utils/axiosCustom";

const BrandPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchCode, setSearchCode] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [brands, setBrands] = useState([]);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Fetch brands from the API on component mount or when search/filter changes
  useEffect(() => {
    const fetchBrands = async () => {
      setLoading(true); // Start loading indicator
      try {
        const response = await instance.get("brand", {
          params: {
            brandName: searchTerm || undefined,
            code: searchCode || undefined,
            status: searchStatus !== "" ? searchStatus === "true" : undefined,
            page: currentPage - 1, // API expects 0-based page
            size: 10,
          },
        });
        if (response.data && response.data.content) {
          const brandData = response.data.content;
          setBrands(brandData);
          setCurrentBrands(brandData);
          setTotalPages(response.data.totalPages); // Set total pages
          setTotalElements(response.data.totalElements); // Set total elements
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching brands");
      } finally {
        setLoading(false); // End loading indicator
      }
    };

    fetchBrands();
  }, [currentPage, searchTerm, searchCode, searchStatus]);

  // Search Handler
  const handleSearch = () => {
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // Pagination Handlers
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage); // Update current page
    }
  };

  // Handle deleting brand with confirmation
  const handleDeleteBrand = async (brandId) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete brand ID ${brandId}?`
    );
    if (confirmDelete) {
      try {
        await instance.delete(`brand/${brandId}`);
        // Refresh brands after deletion
        const updatedBrands = brands.filter(
          (brand) => brand.brandId !== brandId
        );
        setBrands(updatedBrands);
        setCurrentBrands(updatedBrands); // Update currentBrands as well
        alert("Brand deleted successfully.");
      } catch (error) {
        alert("Error deleting brand.");
      }
    }
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Brands</h3>
          {/* Create Brand Button on the right */}
          <Link to={ROUTERS.ADMIN.CREATE_BRANDS}>
            <button className="btn btn-success">Create Brand</button>
          </Link>
          {/* Search Input and Button */}
          <div className="mb-3 mt-4 d-flex">
            {/* Search by brand name */}
            <input
              type="text"
              className="form-control-1"
              placeholder="Search by brand name..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              aria-label="Search by brand name"
            />

            {/* Search by brand code */}
            <input
              type="text"
              className="form-control-1 ms-3"
              placeholder="Search by code..."
              value={searchCode}
              onChange={(e) => setSearchCode(e.target.value)}
              aria-label="Search by code"
            />

            {/* Search by status */}
            <select
              value={searchStatus}
              onChange={(e) => setSearchStatus(e.target.value)}
              className="form-control-1 ms-3"
              aria-label="Search by status"
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>

            <button
              className="btn btn-primary button-search ms-3"
              onClick={handleSearch}
              aria-label="Search"
            >
              Search
            </button>
          </div>

          {/* Show loading message if data is being fetched */}
          {loading && <div className="alert alert-info">Loading brands...</div>}

          {/* Show error message if there's an error */}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Brands Table */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">BrandID</th>
                <th scope="col">Brand name</th>
                <th scope="col">Code</th>
                <th scope="col">Status</th>
                <th scope="col">Create datetime</th>
                <th scope="col">Update datetime</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentBrands.length > 0 ? (
                currentBrands.map((brand) => (
                  <tr key={brand.brandId}>
                    <td>{brand.brandId}</td>
                    <td>{brand.brandName}</td>
                    <td>{brand.code}</td>
                    <td>{brand.status ? "Active" : "Inactive"}</td>
                    <td>{new Date(brand.createdAt).toLocaleString()}</td>
                    <td>{new Date(brand.updatedAt).toLocaleString()}</td>
                    <td>
                      <Link to={ROUTERS.ADMIN.CREATE_BRANDS}>
                        <button
                          className="btn btn-success"
                          aria-label="Edit brand"
                        >
                          <IoCreateOutline />
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeleteBrand(brand.brandId)}
                        aria-label="Delete brand"
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No brands found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-secondary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(BrandPage);
