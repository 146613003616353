import React, { useState, memo } from "react";
import "./style.scss";

const CustomProductPage = () => {
    const [selectedColor, setSelectedColor] = useState("Red");
    const [quantity, setQuantity] = useState(1);

    // Extended list of colors
    const colors = ["Red", "Green", "Blue", "Black", "White", "Yellow", "Orange", "Purple", "Pink", "Gray"];

    const handleColorChange = (color) => {
        setSelectedColor(color);
    };

    const increaseQuantity = () => {
        setQuantity((prev) => prev + 1);
    };

    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity((prev) => prev - 1);
        }
    };

    return (
        <div className="container">
            <div className="product">
                <h2>Customize</h2>

                <div className="product-content">
                    {/* Image Section */}
                    <div className="product-image">
                        <img
                            src={`https://via.placeholder.com/250/${selectedColor === "Red" ? "FF0000" : selectedColor === "Green" ? "00FF00" : selectedColor === "Blue" ? "0000FF" : selectedColor === "Black" ? "000000" : selectedColor === "White" ? "FFFFFF" : selectedColor === "Yellow" ? "FFFF00" : selectedColor === "Orange" ? "FFA500" : selectedColor === "Purple" ? "800080" : selectedColor === "Pink" ? "FFC0CB" : "808080"}`}
                            alt="Product"
                        />
                    </div>

                    {/* Options Section */}
                    <div className="product-options">
                        {/* Color Selection */}
                        <div className="color-options">
                            {colors.map((color) => (
                                <button
                                    key={color}
                                    onClick={() => handleColorChange(color)}
                                    className={`color-button ${selectedColor === color ? "selected" : ""}`}
                                    style={{ backgroundColor: color.toLowerCase() }}
                                />
                            ))}
                        </div>

                        {/* Quantity Selection */}
                        <div className="quantity-options">
                            <h4>Quantity:</h4>
                            <button className="quantity-btn" onClick={decreaseQuantity}>-</button>
                            <span className="quantity-display">{quantity}</span>
                            <button className="quantity-btn" onClick={increaseQuantity}>+</button>
                        </div>

                        {/* Summary */}
                        <div className="summary">
                            <p>Selected Color: {selectedColor}</p>
                            <p>Quantity: {quantity}</p>
                            <button className="cart-btn">Giỏ Hàng</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default memo(CustomProductPage);
