import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "Components";
import instance from "Utils/axiosCustom"; // Axios đã cấu hình
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import "./style.scss";

const VoucherPage = () => {
  // Các state lưu trữ dữ liệu và trạng thái trang
  const [voucherName, setVoucherName] = useState("");
  const [voucherCode, setVoucherCode] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [vouchers, setVouchers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Fetch vouchers từ API khi có thay đổi trong tìm kiếm/filter
  useEffect(() => {
    const fetchVouchers = async () => {
      setLoading(true);
      try {
        const response = await instance.get("/voucher", {
          params: {
            voucherName: voucherName || undefined,
            voucherCode: voucherCode || undefined,
            startDate: startDate || undefined,
            endDate: endDate || undefined,
            page: currentPage - 1,
            size: 10,
          },
        });

        if (response.data && response.data.content) {
          setVouchers(response.data.content);
          setTotalPages(response.data.totalPages);
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching vouchers");
      } finally {
        setLoading(false);
      }
    };

    fetchVouchers();
  }, [currentPage, voucherName, voucherCode, startDate, endDate]);

  // Xử lý tìm kiếm
  const handleSearch = () => {
    setCurrentPage(1);
  };

  // Xử lý phân trang
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Vouchers</h3>
          {/* Tạo Voucher Button */}
          <Link to="/admin/vouchers/create">
            <button className="btn btn-success">Create Voucher</button>
          </Link>

          {/* Input tìm kiếm */}
          <div className="mb-3 mt-4 d-flex">
            {/* Tìm kiếm theo voucherName */}
            <input
              type="text"
              className="form-control-1"
              placeholder="Voucher Name..."
              value={voucherName}
              onChange={(e) => setVoucherName(e.target.value)}
              aria-label="Voucher Name"
            />

            {/* Tìm kiếm theo voucherCode */}
            <input
              type="text"
              className="form-control-1 ms-3"
              placeholder="Voucher Code..."
              value={voucherCode}
              onChange={(e) => setVoucherCode(e.target.value)}
              aria-label="Voucher Code"
            />

            {/* Ngày bắt đầu */}
            <input
              type="date"
              className="form-control-1 ms-3"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              aria-label="Start Date"
            />

            {/* Ngày kết thúc */}
            <input
              type="date"
              className="form-control-1 ms-3"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              aria-label="End Date"
            />

            <button
              className="btn btn-primary button-search ms-3"
              onClick={handleSearch}
              aria-label="Search"
            >
              Search
            </button>
          </div>

          {/* Hiển thị trạng thái loading */}
          {loading && (
            <div className="alert alert-info">Loading vouchers...</div>
          )}

          {/* Hiển thị lỗi nếu có */}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Bảng hiển thị danh sách vouchers */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Voucher ID</th>
                <th scope="col">Voucher Name</th>
                <th scope="col">Voucher Code</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Discount Value</th>
                <th scope="col">Description</th>
                <th scope="col">Status</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {vouchers.length > 0 ? (
                vouchers.map((voucher) => (
                  <tr key={voucher.voucherId}>
                    <td>{voucher.voucherId}</td>
                    <td>{voucher.voucherName}</td>
                    <td>{voucher.voucherCode}</td>
                    <td>{new Date(voucher.startDate).toLocaleDateString()}</td>
                    <td>{new Date(voucher.endDate).toLocaleDateString()}</td>
                    <td>{voucher.value}%</td>
                    <td>{voucher.voucherDescription}</td>
                    <td>{voucher.status ? "Active" : "Inactive"}</td>
                    <td>
                      <Link to={`/admin/vouchers/edit/${voucher.voucherId}`}>
                        <button
                          className="btn btn-success"
                          aria-label="Edit voucher"
                        >
                          <IoCreateOutline />
                        </button>
                      </Link>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        aria-label="Delete voucher"
                        // Xử lý sự kiện delete sau
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10" className="text-center">
                    No vouchers found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Phân trang */}
          <div className="d-flex justify-content-between align-items-center mt-4">
            <button
              className="btn btn-secondary"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(VoucherPage);
