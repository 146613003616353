import React, { useState, memo } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { ROUTERS } from "Utils/router";
import logoImg from "../../../../Assets/users/images/logo/logo.jpg";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = () => {
    if (password === confirmPassword && password.length >= 6) {
      // Submit new password logic
      console.log("Password reset successfully");
    } else if (password.length < 6) {
      setErrorMessage("Password must be at least 6 characters long.");
    } else {
      setErrorMessage("Passwords do not match.");
    }
  };

  return (
    <div className="reset-container">
      <img src={logoImg} alt="Logo" className="login-logo" />
      <h1 className="reset-title">Reset Password</h1>
      <div className="reset-form">
        {/* Error Message */}
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {/* New Password */}
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <input
            type="password"
            id="password"
            placeholder="Enter your new password"
            className="form-control"
            value={password}
            onChange={handlePasswordChange}
          />
        </div>

        {/* Confirm New Password */}
        <div className="form-group">
          <label htmlFor="confirm-password">Confirm New Password</label>
          <input
            type="password"
            id="confirm-new-password"
            placeholder="Re-enter your new password"
            className="form-control"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
          />
        </div>

        {/* Continue Button */}
        <button
          className="reset-button"
          onClick={handleSubmit}
          disabled={!password || !confirmPassword}
        >
          Continue
        </button>

        {/* Option to Sign in if already registered */}
        <div className="signin-option">
          <p>Remembered your password?</p>
          <Link to={ROUTERS.USER.LOGIN}>Sign in</Link>
        </div>
      </div>
    </div>
  );
};

export default memo(ResetPasswordPage);
