import React, { memo } from "react";
import "./style.scss";
import { MdMenuOpen } from "react-icons/md";
import { TbWorld } from "react-icons/tb";
import { TiShoppingCart } from "react-icons/ti";
import { BiEnvelope } from "react-icons/bi";
import { FaRegBell } from "react-icons/fa6";
import { BsFillPersonFill, BsFillDoorOpenFill, BsFillShieldFill } from "react-icons/bs";
import SearchBox from "Components/SearchBox";
import { Link } from "react-router-dom";
import { ROUTERS } from "Utils/router";

const HeaderAdmin = ({ toggleSidebar }) => {
    const icons = [<TbWorld />, <TiShoppingCart />, <BiEnvelope />, <FaRegBell />];

    return (
        <header className="d-flex align-items-center">
            <div className="container-fluid w-100">
                <div className="row d-flex align-items-center w-100">
                    {/* Logo Wrapper */}
                    <div className="col-sm-2">
                        <h1>SmashElite</h1>
                    </div>

                    {/* Search & Menu */}
                    <div className="col-sm-4 d-flex align-items-center">
                        <button className="rounded-circle-1 mr-3" onClick={toggleSidebar}>
                            <MdMenuOpen />
                        </button>
                        <div className="col-sm-4 d-flex align-items-center">
                            <SearchBox />
                        </div>
                    </div>

                    {/* Menu Buttons */}
                    <div className="col-sm-6 d-flex justify-content-end">
                        <div className="button-condition"></div>
                        {icons.map((icon, index) => (
                            <button key={index} className="rounded-circle-2 mr-3">
                                {icon}
                            </button>
                        ))}
                        <div className="myAccWrapper position-relative">
                            <button className="myAcc d-flex align-items-center">
                                <div className="userImg">
                                    <span className="rounded-circle-3">
                                        <Link to=""><BsFillPersonFill /></Link>
                                    </span>
                                </div>

                                <div className="userInfo">
                                    <p className="mb-0">SmashElite@email.com</p>
                                </div>
                            </button>
                            {/* Dropdown Menu */}
                            <ul className="header__menu__dropdown">
                                <li><Link to="/profile"><BsFillPersonFill /> Profile</Link></li>
                                <li><Link to="/settings"><BsFillShieldFill /> Settings</Link></li>
                                <li><Link to={ROUTERS.USER.LOGIN}><BsFillDoorOpenFill /> Logout</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default memo(HeaderAdmin);
