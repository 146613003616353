import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/users/homePage";
import ProfilePage from "./Pages/users/profilePage";
import { ROUTERS } from "./Utils/router";
import MasterLayout from "./Pages/users/theme/masterLayout";
import LoginLayout from "Pages/login/loginLayout";
import AdminLayout from "Pages/admin/theme/adminLayout";
import ProductsPage from "./Pages/users/productsPage";
import ProductDetailPage from "./Pages/users/productDetailPage";
import AboutPage from "./Pages/users/aboutPage";
import CustomizePage from "./Pages/users/customizePage";
import ShoppingCartPage from "Pages/users/shoppingCartPage";
import LoginPage from "./Pages/login/loginLayout/loginPage";
import RegisterPage from "./Pages/login/loginLayout/registerPage";
import ResetPage from "Pages/login/loginLayout/resetPage";
import ResetpasswordPage from "Pages/login/loginLayout/resetpasswordPage";
import CustomProductPage from "Pages/users/customProductPage";
import CustomLayout from "Pages/users/theme/customLayout";
import PaymentPage from "Pages/users/paymentPage";
import DashboardPage from "Pages/admin/theme/adminLayout/dashboardPage";
import UserPage from "Pages/admin/theme/adminLayout/userPage";
import OrderPage from "Pages/admin/theme/adminLayout/orderPage";
import ProductPage from "Pages/admin/theme/adminLayout/productPage";
import CategoryPage from "Pages/admin/theme/adminLayout/categoryPage";
import CreateuserPage from "Pages/admin/theme/adminLayout/createuserPage";
import CreateorderPage from "Pages/admin/theme/adminLayout/createorderPage";
import CreatecategoryPage from "Pages/admin/theme/adminLayout/createcategoryPage";
import CreateproductPage from "Pages/admin/theme/adminLayout/createproductPage";
import OrderHistoryPage from "./Pages/users/orderHistoryPage";
import BrandPage from "Pages/admin/theme/adminLayout/brandPage/brandPage";
import ColorPage from "Pages/admin/theme/adminLayout/colorPage";
import CustomType from "Pages/admin/theme/adminLayout/customType";
import SizePage from "Pages/admin/theme/adminLayout/sizePage";
import VoucherPage from "Pages/admin/theme/adminLayout/voucherPage";

const RouterCustom = () => {
  return (
    <Routes>
      <Route element={<AdminLayout />}>
        <Route path={ROUTERS.ADMIN.DASHBOARD} element={<DashboardPage />} />
        <Route path={ROUTERS.ADMIN.USERS} element={<UserPage />} />
        <Route path={ROUTERS.ADMIN.ORDERS} element={<OrderPage />} />
        <Route path={ROUTERS.ADMIN.PRODUCT} element={<ProductPage />} />
        <Route path={ROUTERS.ADMIN.CATEGORIES} element={<CategoryPage />} />
        <Route path={ROUTERS.ADMIN.CREATE_USERS} element={<CreateuserPage />} />
        <Route
          path={ROUTERS.ADMIN.CREATE_ORDERS}
          element={<CreateorderPage />}
        />
        <Route
          path={ROUTERS.ADMIN.CREATE_PRODUCT}
          element={<CreateproductPage />}
        />
        <Route
          path={ROUTERS.ADMIN.CREATE_CATEGORIES}
          element={<CreatecategoryPage />}
        />
        <Route path={ROUTERS.ADMIN.BRAND} element={<BrandPage />} />
        <Route path={ROUTERS.ADMIN.COLOR} element={<ColorPage />} />
        <Route path={ROUTERS.ADMIN.CUSTOMTYPE} element={<CustomType />} />
        <Route path={ROUTERS.ADMIN.SIZE} element={<SizePage />} />
        <Route path={ROUTERS.ADMIN.VOUCHER} element={<VoucherPage />} />
      </Route>

      {/* Routes that do not need MasterLayout */}
      <Route element={<LoginLayout />}>
        <Route path={ROUTERS.USER.LOGIN} element={<LoginPage />} />
        <Route path={ROUTERS.USER.REGISTER} element={<RegisterPage />} />
        <Route path={ROUTERS.USER.RESET} element={<ResetPage />} />
        <Route
          path={ROUTERS.USER.RESETPASSWORD}
          element={<ResetpasswordPage />}
        />
        {/* <Route path={ROUTERS.USER.DASHBOARD} element={<DashboardPage />} /> */}
      </Route>

      <Route element={<CustomLayout />}>
        <Route
          path={ROUTERS.USER.CUSTOMPRODUCT}
          element={<CustomProductPage />}
        />
        <Route path={ROUTERS.USER.PAYMENT} element={<PaymentPage />} />
      </Route>

      {/* Routes that use MasterLayout */}
      <Route element={<MasterLayout />}>
        <Route path={ROUTERS.USER.HOME} element={<HomePage />} />
        <Route path={ROUTERS.USER.PROFILE} element={<ProfilePage />} />
        <Route path={ROUTERS.USER.PRODUCTS} element={<ProductsPage />} />
        <Route path={ROUTERS.USER.DETAIL} element={<ProductDetailPage />} />
        <Route path={ROUTERS.USER.CART} element={<ShoppingCartPage />} />
        <Route path={ROUTERS.USER.CUSTOMIZE} element={<CustomizePage />} />
        <Route path={ROUTERS.USER.ABOUT} element={<AboutPage />} />
        <Route
          path={ROUTERS.USER.ORDERHISTORY}
          element={<OrderHistoryPage />}
        />
      </Route>
    </Routes>
  );
};

export default RouterCustom;
