import React, { useState, useEffect, memo } from "react";
import Breadcrumb from "../theme/breadcrumb";
//import axios from "axios";
import "./style.scss"; // Custom styles for this page
import instance from "Utils/axiosCustom";
const OrderHistoryPage = () => {
  const [orders, setOrders] = useState([]); // List of orders
  const [loading, setLoading] = useState(true); // Loading state
  const [selectedOrder, setSelectedOrder] = useState(null); // Order selected for details
  const [customOptions, setCustomOptions] = useState({}); // Custom options for each product

  // const instance = axios.create({
  //   baseURL: 'https://joint-socially-pipefish.ngrok-free.app/api/v1/',
  //   withCredentials: true,
  //   headers: {
  //     "Access-Control-Allow-Origin": "*",
  //     "ngrok-skip-browser-warning": "true",
  //   },
  // });

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const customerId = userInfo?.usersID;

    if (customerId) {
      const fetchOrderDetails = async () => {
        try {
          const response = await instance.get(`carts/order/${customerId}`);
          console.log("order", response.data);

          if (response.data) {
            setOrders(response.data); // Save all order data
          } else {
            console.error("Invalid API structure", response.data);
          }
        } catch (error) {
          console.error("Error fetching order details:", error);
        } finally {
          setLoading(false); // Turn off loading after fetch
        }
      };
      fetchOrderDetails();
    }
  }, []);

  // Show order details on the page instead of modal
  const handleShowDetails = (order) => {
    setSelectedOrder(order);
  };

  // Handle custom options (text and image) toggling
  const handleCustomOptionToggle = (productId) => {
    setCustomOptions((prev) => ({
      ...prev,
      [productId]: {
        ...(prev[productId] || {}),
        showCustomOptions: !prev[productId]?.showCustomOptions,
      },
    }));
  };

  // Handle text option toggle
  const handleTextOptionToggle = (productId) => {
    setCustomOptions((prev) => ({
      ...prev,
      [productId]: {
        ...(prev[productId] || {}),
        textOption: !prev[productId]?.textOption,
      },
    }));
  };

  // Handle image option toggle
  const handleImageOptionToggle = (productId) => {
    setCustomOptions((prev) => ({
      ...prev,
      [productId]: {
        ...(prev[productId] || {}),
        imageOption: !prev[productId]?.imageOption,
      },
    }));
  };

  // Handle custom text change
  const handleCustomTextChange = (productId, index, text) => {
    setCustomOptions((prev) => ({
      ...prev,
      [productId]: {
        ...(prev[productId] || {}),
        customText: {
          ...prev[productId]?.customText,
          [index]: text,
        },
      },
    }));
  };

  const handleImageUpload = (productId, index, file) => {
    setCustomOptions((prev) => ({
      ...prev,
      [productId]: {
        ...(prev[productId] || {}),
        customImage: {
          ...prev[productId]?.customImage,
          [index]: file, // Lưu tệp file trực tiếp thay vì Base64
        },
      },
    }));
  };

  const handleSaveCustom = async (
    productId,
    orderDetailId,
    orderId,
    currentTotal,
    existingNote,
    existingImages
  ) => {
    const customData = customOptions[productId];
    let additionalCost = 0;
    if (selectedOrder.order?.orderStatus !== "PENDING") {
      alert("Trạng thái đơn hàng không phải là 'PENDING', không thể chỉnh sửa thông tin tùy chỉnh.");
      return;
    }
    // Kiểm tra xem currentTotal có phải là một số hợp lệ hay không
    if (isNaN(currentTotal)) {
      alert("Tổng tiền hiện tại không hợp lệ.");
      return;
    }

    // Tính số lượng custom chữ hiện tại và số lượng đã có (nếu có)
    const existingTextCount = existingNote
      ? existingNote.split(", ").length
      : 0;
    const newTextCount = customData?.customText
      ? Object.keys(customData.customText).length
      : 0;
    const textCountDifference = Math.max(0, newTextCount - existingTextCount); // Chỉ cộng thêm số tùy chỉnh mới

    // Tính chi phí cho custom chữ mới (25.000 VND cho mỗi tùy chỉnh chữ mới)
    additionalCost += textCountDifference * 25000;

    // Tính số lượng custom ảnh hiện tại và số lượng đã có (nếu có)
    const existingImageCount = existingImages ? existingImages.length : 0;
    const newImageCount = customData?.customImage
      ? Object.keys(customData.customImage).length
      : 0;
    const imageCountDifference = Math.max(
      0,
      newImageCount - existingImageCount
    ); // Chỉ cộng thêm số tùy chỉnh mới

    // Tính chi phí cho custom ảnh mới (50.000 VND cho mỗi tùy chỉnh ảnh mới)
    additionalCost += imageCountDifference * 50000;

    // Cập nhật tổng tiền mới cho đơn hàng nếu có tùy chỉnh mới
    console.log("Current Total:", currentTotal);
    console.log("Additional Cost:", additionalCost);

    try {
      // Gửi custom chữ nếu có (sử dụng orderDetailId)
      if (customData?.customText && textCountDifference > 0) {
        const formData = new FormData();
        formData.append(
          "note",
          Object.values(customData.customText).join(", ")
        );
        await instance.put(`/carts/${orderDetailId}/note`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      // Gửi custom ảnh nếu có (sử dụng orderDetailId)
      if (customData?.customImage && imageCountDifference > 0) {
        const formData = new FormData();
        Object.values(customData.customImage).forEach((image) => {
          formData.append("images", image);
        });
        await instance.post(`/carts/${orderDetailId}/images`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      }

      // Nếu có tùy chỉnh mới, gửi tổng tiền mới về API (sử dụng orderId)
      if (textCountDifference > 0 || imageCountDifference > 0) {
        const priceFormData = new FormData();
        priceFormData.append("price", additionalCost.toString()); // Chuyển tổng tiền thành chuỗi và thêm vào FormData

        await instance.put(`/carts/${orderId}/price`, priceFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        alert(
          "Thông tin tùy chỉnh đã được lưu thành công và tổng tiền đơn hàng đã được cập nhật!"
        );
      } else {
        alert("Không có tùy chỉnh mới nào được lưu.");
      }
    } catch (error) {
      console.error("Lỗi khi lưu thông tin tùy chỉnh:", error);
      alert("Đã xảy ra lỗi khi lưu thông tin tùy chỉnh.");
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Breadcrumb name="Order Details" />
      <div className="container">
        <h1>Thông tin đơn hàng</h1>

        {orders.length === 0 ? (
          <p>Không có đơn hàng nào.</p>
        ) : (
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>OrderID</th>
                <th>Mã đơn hàng</th>
                <th>Tên khách hàng</th>
                <th>Ngày tạo</th>
                <th>Số điện thoại</th>
                <th>Địa chỉ</th>
                <th>Tổng tiền</th>
                <th>Trạng thái đơn</th>
                <th>Chi tiết sản phẩm</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((orderData, index) => (
                <tr key={index}>
                  <td>{orderData.order?.orderId}</td>
                  <td>{orderData.order?.orderCode}</td>
                  <td>{orderData.order?.customerName}</td>
                  <td>
                    {new Date(orderData.order?.orderDate).toLocaleDateString()}
                  </td>
                  <td>{orderData.order?.customerPhone}</td>
                  <td>{orderData.order?.customerAddress}</td>
                  <td>{orderData.order?.total} VND</td>
                  <td>{orderData.order?.orderStatus}</td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => handleShowDetails(orderData)}
                    >
                      Xem chi tiết
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {selectedOrder && (
          <div className="product-details mt-4">
            <h2>Chi tiết sản phẩm</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Tên sản phẩm</th>
                  <th>Số lượng</th>
                  <th>Giá</th>
                  <th>Ảnh sản phẩm</th>
                  <th>Tùy chỉnh sản phẩm</th>
                </tr>
              </thead>
              <tbody>
                {selectedOrder.orderDetails.map((detail, index) => (
                  <tr key={index}>
                    <td>{detail.productVariant.product?.productName}</td>
                    <td>{detail.quantity}</td>
                    <td>{detail.price} VND</td>
                    <td>
                      <img
                        src={
                          detail.productVariant.product.productImages[0]
                            ?.imageUrl
                        }
                        alt={detail.productVariant.product?.productName}
                        style={{ width: "100px", height: "auto" }}
                      />
                    </td>
                    <td>
                      
                      
                      <div>
                        <label>
                          <input
                            type="checkbox"
                            checked={
                              customOptions[
                                detail.productVariant.product.productId
                              ]?.showCustomOptions || false
                            }
                            onChange={() =>
                              handleCustomOptionToggle(
                                detail.productVariant.product.productId
                              )
                            }
                          />
                          Tùy chỉnh sản phẩm
                        </label>

                        {customOptions[detail.productVariant.product.productId]
                          ?.showCustomOptions && (
                          <div className="custom-options-container d-flex justify-content-around align-items-center">
                            <div className="d-flex align-items-center">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={
                                    customOptions[
                                      detail.productVariant.product.productId
                                    ]?.textOption || false
                                  }
                                  onChange={() =>
                                    handleTextOptionToggle(
                                      detail.productVariant.product.productId
                                    )
                                  }
                                />
                                Custom Chữ (25.000 VNĐ)
                              </label>

                              {customOptions[
                                detail.productVariant.product.productId
                              ]?.textOption && (
                                <div className="d-flex">
                                  {[...Array(detail.quantity)].map((_, idx) => {
                                    const notes = detail.note
                                      ? detail.note.split(",")
                                      : []; // Phân tách detail.note thành mảng
                                    return (
                                      <input
                                        key={idx}
                                        type="text"
                                        value={
                                          customOptions[
                                            detail.productVariant.product
                                              .productId
                                          ]?.customText?.[idx] ||
                                          notes[idx]?.trim() ||
                                          ""
                                        } // Hiển thị giá trị của từng phần note tương ứng
                                        onChange={(e) =>
                                          handleCustomTextChange(
                                            detail.productVariant.product
                                              .productId,
                                            idx,
                                            e.target.value
                                          )
                                        }
                                        placeholder={`Nhập chữ tùy chỉnh ${
                                          idx + 1
                                        }`}
                                        className="form-control ms-2"
                                        style={{
                                          width: "150px",
                                          marginRight: "10px",
                                        }}
                                      />
                                    );
                                  })}
                                </div>
                              )}
                            </div>

                            <div className="d-flex align-items-center ms-3">
                              <label>
                                <input
                                  type="checkbox"
                                  checked={
                                    customOptions[
                                      detail.productVariant.product.productId
                                    ]?.imageOption || false
                                  }
                                  onChange={() =>
                                    handleImageOptionToggle(
                                      detail.productVariant.product.productId
                                    )
                                  }
                                />
                                Custom Ảnh (50.000 VNĐ)
                              </label>

                              {customOptions[
                                detail.productVariant.product.productId
                              ]?.imageOption && (
                                <div className="d-flex">
                                  {[...Array(detail.quantity)].map((_, idx) => (
                                    <div
                                      key={idx}
                                      className="d-flex flex-column align-items-center"
                                    >
                                      {customOptions[
                                        detail.productVariant.product.productId
                                      ]?.customImage?.[idx] ? (
                                        <img
                                          src={URL.createObjectURL(
                                            customOptions[
                                              detail.productVariant.product
                                                .productId
                                            ]?.customImage?.[idx]
                                          )}
                                          alt="Custom"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            marginBottom: "10px",
                                          }}
                                        />
                                      ) : detail.orderDetailImages[idx]
                                          ?.imageUrl ? (
                                        <img
                                          src={
                                            detail.orderDetailImages[idx]
                                              ?.imageUrl
                                          }
                                          alt="Custom"
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            marginBottom: "10px",
                                          }}
                                        />
                                      ) : null}
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                          handleImageUpload(
                                            detail.productVariant.product
                                              .productId,
                                            idx,
                                            e.target.files[0]
                                          )
                                        }
                                        className="form-control"
                                      />
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <button
                        className="btn btn-success mt-2"
                        onClick={() =>
                          handleSaveCustom(
                            detail.productVariant.product.productId,
                            detail.orderDetailId,
                            selectedOrder.order?.orderId,
                            selectedOrder.order?.total,
                            detail.note, // Tùy chỉnh chữ đã có
                            detail.orderDetailImages // Tùy chỉnh ảnh đã có
                          )
                        }
                        disabled={selectedOrder.order?.orderStatus !== "PENDING"} // Chỉ cho phép lưu khi PENDING

                      >
                        Lưu thông tin custom
                      </button>

                      
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(OrderHistoryPage);
