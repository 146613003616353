import React, { memo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Sidebar } from "Components";
import instance from "Utils/axiosCustom"; // Tùy chỉnh axios đã cấu hình
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline, MdImage, MdViewList } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import Modal from "react-modal";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss"; // Style cho trang

// Thiết lập Modal
Modal.setAppElement("#root");

const ProductPage = () => {
  // Các state lưu trữ dữ liệu và trạng thái trang
  const [searchProductName, setSearchProductName] = useState("");
  const [searchProductCode, setSearchProductCode] = useState("");
  const [searchMinPrice, setSearchMinPrice] = useState("");
  const [searchMaxPrice, setSearchMaxPrice] = useState("");
  const [searchCategory, setSearchCategory] = useState("");
  const [searchBrand, setSearchBrand] = useState(""); // State cho brand
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // States for modal
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isVariantsModalOpen, setVariantsModalOpen] = useState(false);

  // Fetch products from API when page is mounted or search/filter changes
  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Bắt đầu hiển thị trạng thái loading
      try {
        const response = await instance.get("product", {
          params: {
            productName: searchProductName || undefined,
            productCode: searchProductCode || undefined,
            minPrice: searchMinPrice || undefined,
            maxPrice: searchMaxPrice || undefined,
            category: searchCategory || undefined,
            brand: searchBrand || undefined, // Thêm brand filter
            page: currentPage - 1, // API yêu cầu page bắt đầu từ 0
            size: 10, // Bạn có thể điều chỉnh số lượng sản phẩm hiển thị trên mỗi trang
          },
        });

        if (response.data && response.data.content) {
          setProducts(response.data.content); // Lưu dữ liệu products
          setTotalPages(response.data.totalPages); // Tổng số trang
        } else {
          setErrorMessage("Invalid API structure");
        }
      } catch (error) {
        setErrorMessage("Error fetching products");
      } finally {
        setLoading(false); // Kết thúc trạng thái loading
      }
    };

    fetchProducts();
  }, [
    currentPage,
    searchProductName,
    searchProductCode,
    searchMinPrice,
    searchMaxPrice,
    searchCategory,
    searchBrand, // Thêm searchBrand vào dependency array
  ]);

  // Xử lý tìm kiếm
  const handleSearch = () => {
    setCurrentPage(1); // Đặt lại trang về 1 khi thay đổi điều kiện tìm kiếm
  };

  // Hàm thay đổi trang
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage); // Chuyển trang hiện tại
    }
  };

  // Xử lý xoá sản phẩm
  const handleDeleteProduct = (productId) => {
    const deleteProduct = async () => {
      try {
        await instance.delete(`product/${productId}`);
        toast.success("Product deleted successfully!");
        setProducts(
          products.filter((product) => product.productId !== productId)
        );
      } catch (error) {
        toast.error("Error deleting product.");
      }
    };

    if (window.confirm("Are you sure you want to delete this product?")) {
      deleteProduct();
    }
  };

  // Xử lý mở modal xem hình ảnh sản phẩm
  const handleViewImage = (productId) => {
    const product = products.find((product) => product.productId === productId);
    setSelectedProduct(product);
    setImageModalOpen(true);
  };

  // Xử lý mở modal xem variants sản phẩm
  const handleViewVariants = (productId) => {
    const product = products.find((product) => product.productId === productId);
    setSelectedProduct(product);
    setVariantsModalOpen(true);
  };

  // Hàm trả về giá trị nếu null hoặc undefined
  const getValue = (value) => {
    return value || "Chưa có thông tin"; // Xử lý giá trị null
  };

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Products</h3>
          {/* Tạo Product Button */}
          <Link to="/admin/products/create">
            <button className="btn btn-success">Create Product</button>
          </Link>

          {/* Input tìm kiếm */}
          <div className="mb-3 mt-4 d-flex">
            <input
              type="text"
              className="form-control-1"
              placeholder="Search by product name..."
              value={searchProductName}
              onChange={(e) => setSearchProductName(e.target.value)}
              aria-label="Search by product name"
            />
            <input
              type="text"
              className="form-control-1 ms-3"
              placeholder="Search by product code..."
              value={searchProductCode}
              onChange={(e) => setSearchProductCode(e.target.value)}
              aria-label="Search by product code"
            />
            <input
              type="number"
              className="form-control-1 ms-3"
              placeholder="Min Price"
              value={searchMinPrice}
              onChange={(e) => setSearchMinPrice(e.target.value)}
              aria-label="Min Price"
            />
            <input
              type="number"
              className="form-control-1 ms-3"
              placeholder="Max Price"
              value={searchMaxPrice}
              onChange={(e) => setSearchMaxPrice(e.target.value)}
              aria-label="Max Price"
            />
            <input
              type="text"
              className="form-control-1 ms-3"
              placeholder="Search by category..."
              value={searchCategory}
              onChange={(e) => setSearchCategory(e.target.value)}
              aria-label="Search by category"
            />
            <input
              type="text"
              className="form-control-1 ms-3"
              placeholder="Search by brand..."
              value={searchBrand}
              onChange={(e) => setSearchBrand(e.target.value)}
              aria-label="Search by brand"
            />
            <button
              className="btn btn-primary button-search ms-3"
              onClick={handleSearch}
              aria-label="Search"
            >
              Search
            </button>
          </div>

          {/* Hiển thị trạng thái loading */}
          {loading && (
            <div className="alert alert-info">Loading products...</div>
          )}

          {/* Hiển thị lỗi nếu có */}
          {errorMessage && (
            <div className="alert alert-danger">{errorMessage}</div>
          )}

          {/* Bảng hiển thị danh sách products */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Product ID</th>
                <th scope="col">Product Name</th>
                <th scope="col">Code</th>
                <th scope="col">Price</th>
                <th scope="col">Category</th>
                <th scope="col">Brand</th>
                <th scope="col">Create datetime</th>
                <th scope="col">Update datetime</th>
                <th>Edit</th>
                <th>Delete</th>
                <th>View Image</th>
                <th>View Variants</th>
              </tr>
            </thead>
            <tbody>
              {products.length > 0 ? (
                products.map((product) => (
                  <tr key={product.productId}>
                    <td>{product.productId}</td>
                    <td>{getValue(product.productName)}</td>
                    <td>{getValue(product.code)}</td>
                    <td>${product.price.toFixed(2)}</td>
                    <td>{getValue(product.category?.categoryName)}</td>
                    <td>{getValue(product.brand?.brandName)}</td>
                    <td>{new Date(product.createdAt).toLocaleString()}</td>
                    <td>{new Date(product.updatedAt).toLocaleString()}</td>
                    <td>
                      <Link to={`/admin/products/edit/${product.productId}`}>
                        <IoCreateOutline size={20} />
                      </Link>
                    </td>
                    <td>
                      <MdDeleteOutline
                        size={20}
                        style={{ color: "red", cursor: "pointer" }}
                        onClick={() => handleDeleteProduct(product.productId)}
                      />
                    </td>
                    <td>
                      <MdImage
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewImage(product.productId)}
                      />
                    </td>
                    <td>
                      <MdViewList
                        size={20}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleViewVariants(product.productId)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="12" className="text-center">
                    No products found
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Hiển thị phân trang */}
          <div className="d-flex justify-content-between">
            <div className="text-left">
              <button
                className="btn btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </div>
            <div className="text-center">
              Page {currentPage} of {totalPages}
            </div>
            <div className="text-right">
              <button
                className="btn btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isImageModalOpen}
        onRequestClose={() => setImageModalOpen(false)}
        contentLabel="Product Image"
        style={{
          content: {
            width: "400px",
            height: "auto",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "10px",
          },
        }}
      >
        <h2>Product Images</h2>
        <button
          onClick={() => setImageModalOpen(false)}
          className="btn btn-danger"
        >
          Close
        </button>
        <div className="modal-body">
          {/* Kiểm tra nếu sản phẩm có hình ảnh */}
          {selectedProduct?.productImages &&
          selectedProduct.productImages.length > 0 ? (
            selectedProduct.productImages.map((image) => (
              <img
                key={image.imageId}
                src={image.imageUrl}
                alt="Product"
                style={{ maxWidth: "100%", marginBottom: "10px" }}
              />
            ))
          ) : (
            <p>No images available.</p>
          )}
        </div>
      </Modal>

      {/* Modal xem variants sản phẩm */}
      <Modal
        isOpen={isVariantsModalOpen}
        onRequestClose={() => setVariantsModalOpen(false)}
        contentLabel="Product Variants"
        style={{
          content: {
            width: "700px",
            height: "auto",
            margin: "0 auto",
            padding: "20px",
            borderRadius: "10px",
          },
        }}
      >
        <h2>Product Variants</h2>
        <button
          onClick={() => setVariantsModalOpen(false)}
          className="btn btn-danger"
        >
          Close
        </button>
        <div className="modal-body">
          {/* Kiểm tra nếu sản phẩm có biến thể */}
          {selectedProduct?.productVariants &&
          selectedProduct.productVariants.length > 0 ? (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Product Name</th> {/* Cột Tên Sản Phẩm */}
                  <th>Size</th>
                  <th>Color</th>
                  <th>Price</th>
                  <th>Stock</th>
                </tr>
              </thead>
              <tbody>
                {selectedProduct.productVariants.map((variant) => (
                  <tr key={variant.variantId}>
                    {/* Hiển thị tên sản phẩm */}
                    <td>{selectedProduct.productName}</td>
                    <td>{variant.size || "No size"}</td> {/* Hiển thị size */}
                    <td>{variant.color || "No color"}</td>
                    <td>${variant.price.toFixed(2)}</td>
                    <td>{variant.stock}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No variants available.</p>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default memo(ProductPage);
