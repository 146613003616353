import React, { memo } from "react";
import { Link } from "react-router-dom";
import { BsFacebook, BsInstagram } from "react-icons/bs";
import { MdOutlineMailOutline } from "react-icons/md";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";
import banner1Img from "Assets/users/images/hero/banner-1.jpg";
import banner2Img from "Assets/users/images/hero/banner-2.jpg";
import banner3Img from "Assets/users/images/hero/banner-3.jpg";
import banner4Img from "Assets/users/images/hero/banner-4.jpg";
import banner5Img from "Assets/users/images/hero/banner-5.jpg";
import banner6Img from "Assets/users/images/hero/banner-6.jpg";
import banner7Img from "Assets/users/images/hero/banner-7.jpg";

const AboutPage = () => {
  return (
    <>
      <Breadcrumb name="About" />

      {/* Hero Section */}
      <div className="hero-section">
        <img src={banner1Img} alt="Hero Banner" className="hero-image" />
      </div>

      {/* About Section */}
      <div className="about-section">
        <h1>About Us</h1>
        <h5>
          We specialize in custom-designed badminton accessories, crafted based
          on customer specifications and requirements.
        </h5>
        <h3>SLOGAN</h3>
      </div>

      {/* Product Showcase Section */}
      <div className="product-showcase">
        <img src={banner2Img} alt="Product 1" className="product-image-hero" />
        <img src={banner3Img} alt="Product 2" className="product-image-hero" />
      </div>

      {/* Accessory Customization Section */}
      <div className="customization-info">
        <h5>Accessory products the group buys and customizes themselves.</h5>
      </div>

      {/* Additional Product Showcase */}
      <div className="additional-products">
        <img src={banner4Img} alt="Accessory 1" className="product-image" />
        <img src={banner5Img} alt="Accessory 2" className="product-image" />
        <img src={banner6Img} alt="Accessory 3" className="product-image" />
        <img src={banner7Img} alt="Accessory 4" className="product-image" />
      </div>

      {/* Contact Section */}
      <div className="contact-section">
        <h1>Connect With Us</h1>
        <h2>+0907087145 </h2>
        <div className="social-links">
          <ul>
            <li>
              <Link to="https://www.facebook.com/profile.php?id=61565988783148">
                <BsFacebook />
              </Link>
            </li>
            <li>
              <Link to="https://www.instagram.com/sme_badminton/">
                <BsInstagram />
              </Link>
            </li>
            <li>
              <Link to="mailto:smashelite201@gmail.com">
                <MdOutlineMailOutline />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="contact-section-1"></div>
    </>
  );
};

export default memo(AboutPage);
