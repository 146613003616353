import React, { useState, useEffect, memo } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";
import { formatter } from "Utils/formatter";
import instance from "Utils/axiosCustom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductDetailPage = () => {
  const { id } = useParams();

  const [productDetail, setProductDetail] = useState(null);
  const [quantity, setQuantity] = useState(1);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUserInfo = localStorage.getItem("userInfo");
    if (storedUserInfo) {
      setUserInfo(JSON.parse(storedUserInfo));
    }

    const fetchProductDetail = async () => {
      let allProducts = [];
      let pageNumber = 0;
      let totalPages = 1;

      try {
        while (pageNumber < totalPages) {
          const response = await instance.get(`product/active?page=${pageNumber}&size=10`);
          if (response.data && response.data.content) {
            allProducts = [...allProducts, ...response.data.content];
            totalPages = response.data.totalPages;
            pageNumber += 1;
          } else {
            console.error("Invalid API structure", response.data);
            break;
          }
        }
        const product = allProducts.find((item) => item.productVariantId === parseInt(id));
        setProductDetail(product);
      } catch (error) {
        console.error("Error fetching product:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetail();
  }, [id]);

  const handleQuantityChange = (e) => setQuantity(e.target.value);

  const addToCart = async () => {
    if (!userInfo) {
      toast.warn("Bạn cần đăng nhập trước khi thêm sản phẩm vào giỏ hàng.", { position: "top-center" });
      return;
    }

    try {
      const payload = {
        customerId: parseInt(userInfo.usersID),
        productVariantId: parseInt(id),
        quantity: quantity,
      };

      const response = await instance.post("carts/addToCart", payload);
      if (response.status === 200) {
        toast.success("Sản phẩm đã được thêm vào giỏ hàng!", { position: "top-center" });
      } else {
        toast.error("Không thể thêm sản phẩm vào giỏ hàng.", { position: "top-center" });
      }
    } catch (error) {
      toast.error("Lỗi khi thêm sản phẩm vào giỏ hàng.", { position: "top-center" });
    }
  };

  if (loading) {
    return <div className="loading-spinner">Đang tải sản phẩm...</div>;
  }

  if (!productDetail) {
    return <p>Không tìm thấy sản phẩm.</p>;
  }

  return (
    <>
      <Breadcrumb name="Chi tiết sản phẩm" />
      <ToastContainer />
      <div className="container product-detail-container">
        <div className="row">
          <div className="col-lg-6 product__detail__pic">
            <img
              src={productDetail.product.productImages[0]?.imageUrl}
              alt="product-pic"
              className="product-image"
            />
          </div>
          <div className="col-lg-6 product__detail__text">
            <div className="detail">
              <h2 className="product-name">{productDetail.product.productName}</h2>
              <h3 className="product-price">{formatter(productDetail.price)}</h3>
              <p className="product-description">{productDetail.product.description}</p>
              <ul className="product-status">
                <li>
                  <b>Tình trạng:</b> <span>{productDetail.stock > 0 ? "Còn hàng" : "Hết hàng"}</span>
                </li>
              </ul>
              <h5>Thông tin chi tiết</h5>
              <ul className="product-details">
                <li>Màu sắc: {productDetail.color ? productDetail.color.colorValue : "Không có"}</li>
                <li>Thương hiệu: {productDetail.product.brand.brandName}</li>
                <li>Danh mục: {productDetail.product.category.categoryName}</li>
              </ul>
              <div className="add-to-cart">
                <input
                  type="number"
                  value={quantity}
                  min="1"
                  onChange={handleQuantityChange}
                  className="quantity-input"
                />
                <button onClick={addToCart} className="add-to-cart-button">Thêm vào giỏ hàng</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(ProductDetailPage);
