import React, { memo, useState } from "react";
import "./style.scss";

const PaymentPage = () => {
    const [paymentMethod, setPaymentMethod] = useState("creditCard");
    const [paymentDetails, setPaymentDetails] = useState({
        cardNumber: "",
        expiryDate: "",
        cvv: ""
    });

    const handlePaymentMethodChange = (e) => {
        setPaymentMethod(e.target.value);
    };

    const handlePaymentDetailChange = (e) => {
        const { name, value } = e.target;
        setPaymentDetails(prevDetails => ({ ...prevDetails, [name]: value }));
    };

    const handleSubmitPayment = (e) => {
        e.preventDefault();
        // Handle payment submission logic here
        alert("Payment details submitted!");
    };

    return (
        <div className="payment-page container">
            <h2>Payment</h2>
            <form onSubmit={handleSubmitPayment}>
                <div className="form-group">
                    <label>Payment Method</label>
                    <select value={paymentMethod} onChange={handlePaymentMethodChange} className="form-control">
                        <option value="creditCard">Credit Card</option>
                        <option value="cash">Cash</option>
                    </select>
                </div>

                {paymentMethod === "creditCard" && (
                    <div className="credit-card-form">
                        <div className="form-group">
                            <label>Card Number</label>
                            <input
                                type="text"
                                name="cardNumber"
                                value={paymentDetails.cardNumber}
                                onChange={handlePaymentDetailChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>Expiry Date</label>
                            <input
                                type="text"
                                name="expiryDate"
                                value={paymentDetails.expiryDate}
                                onChange={handlePaymentDetailChange}
                                className="form-control"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label>CVV</label>
                            <input
                                type="text"
                                name="cvv"
                                value={paymentDetails.cvv}
                                onChange={handlePaymentDetailChange}
                                className="form-control"
                                required
                            />
                        </div>
                    </div>
                )}

                {paymentMethod === "cash" && (
                    <div className="cash-payment-info">
                        <p>Paying with cash. Please prepare the amount in VND.</p>
                    </div>
                )}

                <button type="submit" className="btn btn-primary">Submit Payment</button>
            </form>
        </div>
    );
};

export default memo(PaymentPage);
