import { memo, useState, useEffect, useCallback } from "react";
import "./style.scss";
import { Sidebar } from "Components";
import { IoCreateOutline } from "react-icons/io5";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { ROUTERS } from "Utils/router";
import instance from "Utils/axiosCustom";

const UserPage = () => {
  // States for filters and pagination
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState(true);

  const [users, setUsers] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [size, setSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Temporary state for storing search filters
  const [searchParams, setSearchParams] = useState({
    name: "",
    email: "",
    status: true,
  });

  // Fetch users from the API when the page or size changes
  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      setError(null);
      try {
        const { data } = await instance.get("/users", {
          params: {
            page: currentPage - 1, // currentPage is 1-based, API is 0-based
            size: size,
            email: searchParams.email || undefined,
            status:
              searchParams.status !== "" ? searchParams.status : undefined,
            name: searchParams.name || undefined,
          },
        });
        if (data && Array.isArray(data.content)) {
          setUsers(data.content);
          setTotalPages(data.totalPages);
          updatePageUsers(currentPage, data.content);
        } else {
          throw new Error("Invalid API structure");
        }
      } catch (error) {
        setError("Error fetching users: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [currentPage, size, searchParams]);

  // Update the current users displayed on the page
  const updatePageUsers = useCallback(
    (page, usersToUpdate = users) => {
      const startIndex = (page - 1) * size;
      setCurrentUsers(usersToUpdate.slice(startIndex, startIndex + size));
    },
    [users, size]
  );

  // Search Handler: only updates search parameters and triggers API call when clicked
  const handleSearch = () => {
    setSearchParams({ name, email, status });
    setCurrentPage(1); // Reset to the first page when searching
  };

  // Status change handler
  const handleStatusChange = (e) => {
    setStatus(e.target.value === "true" ? true : false);
  };

  // Pagination Handlers
  const handlePrevPage = () => {
    if (currentPage > 1) {
      const newPage = currentPage - 1;
      setCurrentPage(newPage); // Update the current page state
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      const newPage = currentPage + 1;
      setCurrentPage(newPage); // Update the current page state
    }
  };

  // Render loading state
  if (loading) {
    return <div>Loading...</div>;
  }

  // Render error state
  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="main d-flex">
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <h3 className="hd">Users</h3>

          {/* Search Filters */}
          <div className="filter-section mb-4">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="form-control"
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
            />
            <select
              value={status}
              onChange={handleStatusChange}
              className="form-control"
            >
              <option value="">Select Status</option>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
            <button className="btn btn-primary ms-3" onClick={handleSearch}>
              Search
            </button>
          </div>

          {/* Table rendering */}
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Status</th>
                <th>User</th>
                <th>UserID</th>
                <th>Create datetime</th>
                <th>Update datetime</th>
                <th>Account name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {currentUsers.length > 0 ? (
                currentUsers.map((user) => (
                  <tr key={user.usersID}>
                    <td>{user.status ? "Active" : "Inactive"}</td>
                    <td>{user.username}</td>
                    <td>{user.usersID}</td>
                    <td>{user.createdAt}</td>
                    <td>{user.updatedAt}</td>
                    <td>{user.accountName}</td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.role}</td>
                    <td>
                      <div className="action-buttons">
                        <Link to={ROUTERS.ADMIN.CREATE_USERS}>
                          <button
                            className="btn btn-success"
                            aria-label="Edit user"
                          >
                            <IoCreateOutline />
                          </button>
                        </Link>
                        <button
                          className="btn btn-danger"
                          //onClick={() => handleDeleteUser(user.usersID)}
                          aria-label="Delete user"
                        >
                          <MdDeleteOutline />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="10">No users found</td>
                </tr>
              )}
            </tbody>
          </table>

          {/* Pagination */}
          <div className="pagination-section mt-3">
            <div className="d-flex justify-content-between align-items-center">
              {/* Pagination Controls */}
              <div className="d-flex">
                <button
                  className="btn btn-primary"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  Previous
                </button>
                <span className="mx-2">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="btn btn-primary"
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  Next
                </button>
              </div>

              {/* Page Size Control */}
              {/* <div className="page-size-control">
                <label className="me-2">Page Size:</label>
                <select
                  value={size}
                  onChange={(e) => setSize(parseInt(e.target.value))}
                  className="form-control"
                >
                  <option value={5}>5</option>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(UserPage);
