import React, { memo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.scss";
import { ROUTERS } from "Utils/router";
import instance from "Utils/axiosCustom";

const RegisterPage = () => {
    const [form, setForm] = useState({
        name: "",
        email: "",
        password: "",
        phone: "",
    });
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { id, value } = e.target;
        setForm({ ...form, [id]: value });
    };

    const handleSubmit = async () => {
        // Validate that all fields are filled
        if (!form.name || !form.email || !form.password || !form.phone) {
            setError("Vui lòng điền đầy đủ thông tin.");
            return;
        }

        // Validate password length
        if (form.password.length < 6) {
            setError("Mật khẩu phải có ít nhất 6 ký tự.");
            return;
        }

        // Validate phone number length and format
        const phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(form.phone)) {
            setError("Số điện thoại phải có đúng 10 chữ số.");
            return;
        }

        try {
            const response = await instance.post("auth/register", {
                name: form.name,
                email: form.email,
                password: form.password,
                phone: form.phone,
            });
            if (response.status === 200) {
                console.log("Đăng ký thành công", response.data);
                setSuccess("Đăng ký thành công. Bạn có thể đăng nhập ngay bây giờ.");
                setError(null); // Clear any previous errors
                // Redirect the user to the login page
                navigate(ROUTERS.USER.LOGIN);
            }
        } catch (error) {
            console.error("Lỗi khi đăng ký:", error);
            setError("Email đã tồn tại. Vui lòng kiểm tra lại thông tin.");
            setSuccess(null); // Clear any previous success message
        }
    };

    return (
        <div className="register-container">
            <h1 className="register-title">Tạo tài khoản của bạn</h1>
            <div className="register-form">
                {/* Display error if exists */}
                {error && <p className="error-message">{error}</p>}
                {success && <p className="success-message">{success}</p>}

                {/* Username */}
                <div className="form-group">
                    <label htmlFor="name">Tên</label>
                    <input
                        type="text"
                        id="name"
                        value={form.name}
                        onChange={handleChange}
                        placeholder="Nhập tên của bạn"
                        className="form-control"
                    />
                </div>

                {/* Email */}
                <div className="form-group">
                    <label htmlFor="email">Địa chỉ Email</label>
                    <input
                        type="email"
                        id="email"
                        value={form.email}
                        onChange={handleChange}
                        placeholder="Nhập địa chỉ email"
                        className="form-control"
                    />
                </div>

                {/* Password */}
                <div className="form-group">
                    <label htmlFor="password">Mật khẩu</label>
                    <input
                        type="password"
                        id="password"
                        value={form.password}
                        onChange={handleChange}
                        placeholder="Nhập mật khẩu"
                        className="form-control"
                    />
                </div>

                {/* Phone number */}
                <div className="form-group">
                    <label htmlFor="phone">Số điện thoại</label>
                    <input
                        type="tel"
                        id="phone"
                        value={form.phone}
                        onChange={handleChange}
                        placeholder="Nhập số điện thoại"
                        className="form-control"
                    />
                </div>

                {/* Register Button */}
                <button
                    className="register-button"
                    onClick={handleSubmit}
                    disabled={
                        !form.name ||
                        !form.email ||
                        !form.password ||
                        !form.phone
                    }
                >
                    Đăng ký
                </button>

                {/* Link to Login page */}
                <div className="signin-link">
                    <p>Bạn đã có tài khoản?</p>
                    <Link to={ROUTERS.USER.LOGIN}>Đăng nhập</Link>
                </div>
            </div>
        </div>
    );
};

export default memo(RegisterPage);
