import React from "react";
import Breadcrumb from "../theme/breadcrumb";
import "./style.scss";
import Slider from "Components/Slider";

const CustomizePage = () => {
  return (
    <>
      <Breadcrumb name="Customize" />

      <section className="customize-slider">
        <Slider />
      </section>

      <section className="customize-text">
        <p>
          Trong số tất cả các xu hướng đang thịnh hành trong cộng đồng giới trẻ
          hiện nay thì custom phụ kiện về môn thể thao cầu lông nổi lên như một
          trào lưu mới. Đặc biệt chúng rất có ý nghĩa đối với những ai đam mê
          sưu tầm phụ kiện.
        </p>
        <p>
          Bên cạnh đó, vẫn còn nhiều member khi vừa chập chững tìm hiểu khái
          niệm này sẽ cảm thấy vô cùng mơ hồ. Kể cả trong việc tìm kiếm một địa
          chỉ uy tín để thực hiện dịch vụ custom sneakers của mình.
        </p>
        <img
          src="https://thethao365.com.vn/Data/upload/images/Product/Caulong/phu-kien-cau-long.jpg"
          alt="Phụ kiện cầu lông tùy chỉnh, với nhiều phong cách thiết kế độc đáo."
          loading="lazy"
        />

        <h2>1. CUSTOM PHỤ KIỆN CẦU LÔNG LÀ GÌ?</h2>
        <p>
          Thực chất, không chỉ riêng gì giới trẻ mà ngay cả tất cả mọi người,
          đều sẽ ưu tiên ưa chuộng sự độc quyền hơn hết. Chính nhờ ý tưởng táo
          bạo đó mà khi trào lưu custom du nhập vào Việt Nam đã khiến cho các
          bạn trẻ vô cùng thích thú và hưởng ứng nhiệt tình.
        </p>
        <p>
          Nói một cách dễ hiểu, custom là một hình thức độ phụ kiện, giúp cho
          phụ kiện tưởng chừng đã cũ kỹ, lỗi thời của bạn được thay mới hoàn
          toàn thông qua những hình vẽ, màu sơn, acrylic… Mọi ý tưởng custom đều
          xuất phát từ cá tính riêng mỗi người, nên ở những phụ kiện custom, ta
          thường thấy rõ phong cách cá tính, độc lạ mà đối phương thể hiện ra.
        </p>
        <p>
          Trào lưu custom khi du nhập vào Châu Á đã khiến cho các bạn trẻ vô
          cùng thích thú lẫn hưởng ứng nhiệt tình.
        </p>
        <p>
          Nhờ bàn tay khéo léo của những người thợ lành nghề, các mẫu custom đẹp
          sẽ trở nên khác biệt, nổi bật cũng như độc nhất vô nhị không bao giờ
          đụng hàng với bất kỳ mẫu phụ kiện nào.
        </p>
        <img
          src="https://cdn.shopvnb.com/uploads/images/tin_tuc/top-3-cua-hang-ban-vot-cau-long-o-ha-dong-ha-noi-uy-tin-chat-luong-cac-long-thu-nhat-dinh-phai-ghe-1-lan--5.webp"
          alt="Cửa hàng phụ kiện cầu lông nổi tiếng tại Hà Đông, Hà Nội."
          loading="lazy"
        />

        <h2>2. GIÁ CUSTOM PHỤ KIỆN CẦU LÔNG</h2>
        <p>
          Custom Túi: Chọn chất liệu và màu sắc túi phù hợp với phong cách cá
          nhân. Có thể thêm hình vẽ, sơn màu, hoặc in tên để tạo điểm nhấn riêng
          biệt.
        </p>
        <p>
          Băng Trán: Băng trán tùy chỉnh có thể được thiết kế bằng cách thêu tên
          hoặc hình ảnh yêu thích. Ngoài ra, sử dụng các màu sắc nổi bật để thể
          hiện cá tính cũng là một ý tưởng hay.
        </p>
        <p>
          Tùy thuộc vào loại phụ kiện (băng trán, túi,...), việc thêu tên giúp
          tạo nét riêng và cũng mang lại sự độc đáo. Có thể chọn màu chỉ và kiểu
          chữ phù hợp để tạo dấu ấn riêng.
        </p>
        <p>
          Sau cùng, giá custom phụ kiện dao động thấp nhất từ 1.500.000 đồng đến
          12.000.000 triệu đồng hoặc hơn thế nữa tùy theo từng phiên bản riêng
          biệt.
        </p>
        <p>Một số mẫu phụ kiện custom đẹp:</p>
        <img
          src="https://img.lazcdn.com/g/p/6c512f8db0f5a491471e910aa84a7f3e.jpg_360x360q75.jpg_.webp"
          alt="Phụ kiện cầu lông custom với thiết kế độc đáo."
          loading="lazy"
        />

        <h2>3. Ý TƯỞNG CUSTOM PHỤ KIỆN CẦU LÔNG ĐẸP</h2>
        <p>
          Tuy giá thành thuộc phân khúc cao, đắt hơn cả việc tậu riêng cho mình
          một phụ kiện mới khác, nhưng dịch vụ shop custom phụ kiện vẫn được rất
          nhiều bạn trẻ yêu thích và hưởng ứng rộng rãi.
        </p>
        <p>
          Custom túi: - Sơn hoặc vẽ tay, sử dụng màu sơn acrylic hoặc sơn vải để
          tạo các họa tiết, hình ảnh theo sở thích cá nhân. Bạn có thể chọn hình
          minh họa liên quan đến cầu lông hoặc thiết kế sáng tạo khác.
        </p>
        <p>
          Custom băng trán: - In họa tiết hoặc logo nhỏ: Chọn các họa tiết hoặc
          logo nhỏ, như hình cầu lông hoặc chữ viết tắt của tên bạn, để tạo điểm
          nhấn. - Thêu hoặc đính: Thêu tên hoặc slogan ngắn gọn bằng chỉ nổi bật
          để tạo nét riêng. Bạn cũng có thể đính thêm logo nhỏ hoặc huy hiệu. -
          Dùng màu nhuộm: Tạo phong cách tie-dye hoặc nhuộm màu riêng để băng
          trán nổi bật hơn.
        </p>
        <p>
          Thêu tên hoặc họa tiết trên phụ kiện: - Thêu tay hoặc máy: Bạn có thể
          chọn thêu tên bằng tay hoặc sử dụng dịch vụ thêu máy để đảm bảo độ
          chính xác và bền lâu. Chọn màu chỉ nổi bật để tăng phần độc đáo. - Vẽ
          bằng màu chuyên dụng: Nếu không muốn thêu, bạn có thể dùng bút vẽ vải
          chuyên dụng để vẽ trực tiếp lên phụ kiện, tạo kiểu chữ hoặc hình ảnh
          theo sở thích.
        </p>
      </section>
    </>
  );
};

export default CustomizePage;
