import React, { useState, memo, useEffect } from "react";
import "./style.scss";
import { GoMail } from "react-icons/go";
import { BsFacebook, BsInstagram, BsFillPersonFill, BsTelephoneFill } from "react-icons/bs";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { formatter } from "Utils/formatter";
import { IoMenu } from "react-icons/io5";
import { TiShoppingCart } from "react-icons/ti";
import { ROUTERS } from "Utils/router";
import axios from "axios";
import logoImg from "Assets/users/images/logo/logo.jpg";

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isHome, setIsHome] = useState(location.pathname.length <= 1);
    const [isShowCategories, setShowCategories] = useState(isHome);
    const [categories, setCategories] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null); // Trạng thái và thông tin người dùng

    const [menus] = useState([
        {
            name: "Home",
            path: ROUTERS.USER.HOME,
        },
        {
            name: "Products",
            path: ROUTERS.USER.PRODUCTS,
        },
        {
            name: "Customize",
            path: ROUTERS.USER.CUSTOMIZE,
        },
        {
            name: "About",
            path: ROUTERS.USER.ABOUT,
        },
        {
            name: "Order ",
            path: ROUTERS.USER.ORDERHISTORY,
        },
        
    ]);

    // Cấu hình axios instance
    const instance = axios.create({
        baseURL: 'https://joint-socially-pipefish.ngrok-free.app/api/v1/',
        withCredentials: true,
        headers: {
            "Access-Control-Allow-Origin": "*",
            "ngrok-skip-browser-warning": "true",
        },
    });

    useEffect(() => {
        // Kiểm tra trạng thái đăng nhập từ localStorage
        const token = localStorage.getItem('access_token');
        const storedUserInfo = localStorage.getItem('userInfo');
        if (token && storedUserInfo) {
            setIsLoggedIn(true);
            setUserInfo(JSON.parse(storedUserInfo)); // Lưu thông tin người dùng vào state
        }
    }, []);

    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const response = await instance.get("brand/active");
                if (response.data && response.data.content) {
                    setBrands(response.data.content.map((brand) => brand.brandName));
                } else {
                    console.error("Invalid API structure", response.data);
                }
            } catch (error) {
                console.error("Lỗi khi lấy danh mục:", error);
            }
        };

        fetchBrands();
    }, []);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await instance.get("category/active?");
                if (response.data && response.data.content) {
                    setCategories(response.data.content.map((category) => category.categoryName));
                } else {
                    console.error("Invalid API structure", response.data);
                }
            } catch (error) {
                console.error("Lỗi khi lấy danh mục:", error);
            }
        };

        fetchCategories();
    }, []);

    // Hàm xử lý đăng xuất
    const handleLogout = () => {
        const confirmLogout = window.confirm('Bạn có chắc chắn muốn đăng xuất không?');
        if (confirmLogout) {
            localStorage.removeItem('access_token');
            localStorage.removeItem('userInfo');
            setIsLoggedIn(false);
            setUserInfo(null);
            navigate(ROUTERS.USER.HOME);
            alert('Đăng xuất thành công!');
        }
    };

    return (
        <>
            <div className="header__top">
                <div className="container">
                    <div className="row">
                        <div className="col-6 header__top_left">
                            <ul>
                                <li><GoMail />SmashElite@gmail.com</li>
                                <li>Miễn phí ship đơn từ {formatter(500000)}</li>
                            </ul>
                        </div>
                        <div className="col-6 header__top_right">
                            <ul>
                                <li>
                                    <Link to="https://www.facebook.com/profile.php?id=61565988783148"><BsFacebook /></Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/sme_badminton/"><BsInstagram /></Link>
                                </li>
                                {isLoggedIn ? (
                                    <li>
                                        <span>Xin chào, {userInfo ? userInfo.accountName : "Người dùng"}!</span>
                                        <button onClick={handleLogout} style={{ cursor: 'pointer', marginLeft: '10px' }}>
                                            Đăng xuất
                                        </button>
                                    </li>
                                ) : (
                                    <li>
                                        <span>Bạn chưa đăng nhập</span>
                                        <Link to={ROUTERS.USER.LOGIN} style={{ marginLeft: '10px' }}>
                                            Đăng nhập
                                        </Link>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="header__logo">
                            <img src={logoImg} alt="SmashElite Logo" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <nav className="header__menu">
                            <ul>
                                {menus?.map((menu, menuKey) => (
                                    <li key={menuKey} className={menuKey === 0 ? "active" : ""}>
                                        <Link to={menu?.path}>{menu?.name}</Link>
                                        {menu.name === "Products" && categories.length > 0 && (
                                            <ul className="header__menu__dropdown">
                                                {categories.map((category, childKey) => (
                                                    <li key={`${menuKey}-${childKey}`}>
                                                        <Link
                                                            to={`${ROUTERS.USER.PRODUCTS}?category=${encodeURIComponent(category)}`}
                                                        >
                                                            {category}
                                                        </Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>

                    <div className="col-lg-3">
                        <div className="header__cart">
                            
                            <ul>
                                <li>
                                    <Link to={ROUTERS.USER.CART}><TiShoppingCart /> </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default memo(Header);
