import { memo, useState } from "react";
import "./style.scss";
import { Sidebar } from "Components";
import { IoCreateOutline } from "react-icons/io5";
import instance from "Utils/axiosCustom"; // Import instance để gọi API
import { useNavigate } from "react-router-dom"; // Import useNavigate để điều hướng
import { toast, ToastContainer } from "react-toastify"; // Import toast và ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import CSS cho toast

const CreateCategoryPage = () => {
  const [categoryName, setCategoryName] = useState("");
  const navigate = useNavigate(); // Khởi tạo useNavigate để điều hướng

  // Handle input change
  const handleChange = (e) => {
    setCategoryName(e.target.value);
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call API để tạo category mới
      const response = await instance.post("category", {
        categoryName: categoryName,
      });

      if (response.status === 200) {
        navigate("/Categories"); // Điều hướng về trang Category
        toast.success("Category created successfully."); // Hiển thị thông báo thành công
      } else {
        toast.error("Failed to create category."); // Hiển thị thông báo lỗi
      }
    } catch (error) {
      toast.error("Error occurred while creating category."); // Hiển thị thông báo lỗi
    }
  };

  return (
    <div className="main d-flex">
      {/* Sidebar Section */}
      <div className="sidebarWrapper">
        <Sidebar />
      </div>

      {/* Content Section */}
      <div className="content">
        <div className="card shadow border-0 p-3 mt-4">
          <form onSubmit={handleSubmit} className="mb-4">
            {/* Category Name Field */}
            <div className="form-group">
              <label htmlFor="categoryName">Category Name</label>
              <input
                type="text"
                id="categoryName"
                name="categoryName"
                className="form-control"
                value={categoryName}
                onChange={handleChange}
                required
                aria-label="Category Name"
                placeholder="Enter category name"
              />
            </div>

            {/* Submit Button */}
            <button type="submit" className="btn btn-primary">
              <IoCreateOutline /> Create
            </button>
          </form>
        </div>
      </div>

      {/* Toast Container for displaying notifications */}
      <ToastContainer
        position="top-right" // Vị trí hiển thị
        autoClose={5000} // Thời gian tự động đóng (5 giây)
        hideProgressBar={false} // Hiển thị thanh tiến trình
        newestOnTop={false} // Hiển thị thông báo mới nhất ở trên cùng
        closeOnClick // Đóng thông báo khi nhấn vào
        rtl={false} // Hỗ trợ hướng từ phải sang trái
        pauseOnFocusLoss // Tạm dừng thông báo khi người dùng chuyển sang tab khác
        draggable // Cho phép kéo thông báo
        pauseOnHover // Tạm dừng thông báo khi di chuột lên
      />
    </div>
  );
};

export default memo(CreateCategoryPage);
