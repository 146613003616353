import { memo } from "react";
import { Link } from "react-router-dom";
import "./style.scss";
import { ROUTERS } from "Utils/router";
import { BiSolidDashboard } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { RiFileList3Line } from "react-icons/ri";
import { FiBox } from "react-icons/fi";
import { BiCategory } from "react-icons/bi";
import { BsFillExclamationSquareFill, BsFillShieldFill } from "react-icons/bs";
import logoImg from "Assets/users/images/logo/logo.jpg";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logoImg} alt="SmashElite" />
      </div>
      <h3>Main Menu</h3>
      <ul className="sidebar__menu">
        <li key="dashboard">
          <Link to={ROUTERS.ADMIN.DASHBOARD}>
            <BiSolidDashboard /> Dashboard
          </Link>
        </li>
        <li key="users">
          <Link to={ROUTERS.ADMIN.USERS}>
            <BsFillPersonFill /> Users
          </Link>
        </li>
        <li key="orders">
          <Link to={ROUTERS.ADMIN.ORDERS}>
            <RiFileList3Line /> Orders
          </Link>
        </li>
        <li key="products">
          <Link to={ROUTERS.ADMIN.PRODUCT}>
            <FiBox /> Products
          </Link>
        </li>
        <li key="categories">
          <Link to={ROUTERS.ADMIN.CATEGORIES}>
            <BiCategory /> Categories
          </Link>
        </li>
        <li key="brands">
          <Link to={ROUTERS.ADMIN.BRAND}>
            <BiCategory /> Brands
          </Link>
        </li>
        <li key="colors">
          <Link to={ROUTERS.ADMIN.COLOR}>
            <BiCategory /> Colors
          </Link>
        </li>
        <li key="customtype">
          <Link to={ROUTERS.ADMIN.CUSTOMTYPE}>
            <BiCategory /> Custom Types
          </Link>
        </li>
        <li key="sizes">
          <Link to={ROUTERS.ADMIN.SIZE}>
            <BiCategory /> Size
          </Link>
        </li>
        <li key="vouchers">
          <Link to={ROUTERS.ADMIN.VOUCHER}>
            <BiCategory /> Voucher
          </Link>
        </li>
        <li key="settings">
          <Link to="/settings">
            <BsFillShieldFill /> Settings
          </Link>
        </li>
        <li key="about">
          <Link to="/about">
            <BsFillExclamationSquareFill /> About
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default memo(Sidebar);
